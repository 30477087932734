<template>
  <div class="news"> 
    <div class="top">
      <div class="pic">
        <div class="left">
          <img src="../img/news/bgtext.png" alt="" />
        </div>
        <div class="right">
          <img src="../img/news/bgimg1.png" alt="" />
        </div>
        <!-- <img src="../img/news/zx-banner.png" alt="" /> -->
      </div>
    </div>
    <!--精彩-->
    <div class="wonderful">
      <div class="row main">
        <div
          class="col-md-4 col-sm-4 col-xs-6 col-lg-3"
          v-for="(item, index) in dataList"
          :key="index + '8'"
          @click="detail(item.index + 1)"
        >
          <div class="item">
            <img :src="item.src" alt="" />
            <div class="right">
              <div class="title">{{ item.title }}</div>
              <div class="text hidden-xs">
                {{ item.text }}
              </div>
              <div class="date">
                <div>{{ item.date }}</div>
                <div class="arrow">
                  <img src="../img/home/rightArrows.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="item" v-for="(item,index) in bottomNews" :key =index @click="detail(index+3)">
          <div class="right"> 
            <div>{{item.title}}</div>
            <div class="date"><div>{{item.date}}</div><div class="arrow"><img src="../img/home/rightArrows.png" alt=""></div></div>
          </div>
          <img :src="item.src" alt="">
        </div> -->
      </div>
    </div>

    <div class="page" v-if="!flag">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="newsList.length"
        :page-size="8"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      httpList: [
        'https://m.tech.china.com/tech/article/20220921/092022_1146614.html',
        '',
        '',
        "",
        "",
        "https://baijiahao.baidu.com/s?id=1716851355147831341&wfr=spider&for=pc",
        "http://scjg.xiangyang.gov.cn/zwdt/sjyw/202111/t20211115_2634882.shtml",
        // 'http://www.xiangyang.gov.cn/wzsy/sytt2019/202111/t20211112_2632483.shtml',
        "https://baijiahao.baidu.com/s?id=1716237709894371018&wfr=spider&for=pc",
        "http://jyj.xiangyang.gov.cn/zxzx/jydt/202111/t20211111_2632132.shtml",
        "http://news.hubeidaily.net/web/1625/202111/11/959067.html",
        "http://scjg.xiangyang.gov.cn/zwdt/sjyw/202107/t20210730_2537742.shtml",
        "http://www.xf.gov.cn/zxzx/lddt/202107/t20210729_2536487.shtml",
        "",
      ],
      dialogVisible: false,
      index: 0,
      currentIndex: 1,
      flag: false,
      dataList: [],
      newsList: [
      {
          index:0,
          title: "家长可实时监控！襄城校园食堂进入“直播”时代",
          flag: true,
          src: require("../img/pic/title/20.png"),
          date: "2023年05月31日",
          text: "近日，襄城区行政审批局大数据中心联合襄城区教育局共同打造的襄城区学校食品安全监管服务平台，通过互联网+明厨亮灶，提高食品安全管理水平，实现学校食品安全监管管理及时、有效、可追溯。",
        },
      {
          index:1,
          title: "湖北鲲鹏芯荣获《2022年度昇腾AI创新大赛》银奖",
          flag: true,
          src: require("../img/pic/title/19.jpg"),
          date: "2022年9月20日",
          text: "2022年9月20日，以“数智未来，因你而来”为主题的武汉人工智能·昇腾AI创新大赛湖北赛区总决赛在武汉光谷皇冠假日酒店成功举办。基于昇腾MindX SDK的高速路视频结构化联合方案、基于昇思MindSpore搭建的地事通SaaS服务应用、基于昇思MindSpore的城市变化遥感监测平台、基于昇腾的乘客动态监测预警联合方案等47个优秀的AI创新项目亮相决赛现场，共同展现出“湖北AI实力”",
        },
        {
          index:2,
          title: "省市场监管局专题调研社会餐饮及配餐企业的管理",
          flag: true,
          src: require("../img/pic/title/17.jpg"),
          date: "2022年7月11日",
          text: "2022年7月11日，省市场监管朱钦炳，副局长邓仲林，（襄州区教育局，市场监管局王迪，襄州区市监局黄建国局长，城运领导陪同）到襄州区城运调研。主要问题集中在社会餐饮及配餐企业的管理。",
        },
        {
          index:3,
          title: "调研中央厨房 助推团餐产业",
          flag: true,
          src: require("../img/pic/14/2.jpg"),
          date: "2022年6月20日",
          text: "6月16日下午，农工党襄阳市委会调研组、襄阳市学生资助和学校后勤服务指导中心和襄阳市营养学会联合召开襄阳市“中央厨房”产业专题调研座谈会。座谈会邀请襄阳多家中央厨房企业代表、部分学校食堂后勤负责人、襄阳市学校食品安全监管服务平台负责人参加。",
        },
        {
          index:4,
          title: "聚焦城市运行管理“急难愁盼” 探索城市现代治理“襄州方案”",
          flag: true,
          src: require("../img/pic/13/3.png"),
          date: "2022年6月13日",
          text: "为推进全市“一网统管”建设，通过组织开展学习借鉴成效显著的地区经验，形成“比学赶超”良好氛围，6月10日，襄州区城市运行管理中心将已经投入使用的校园“明厨亮灶”特色应用场景向与会者进行了介绍和实际演练，襄阳市人大常委会副主任吉虹，襄州区领导张双成、王宏举、刘德玉、江海军，各县（市、区）、开发区分管领导及城运中心主任参加活动。",
        },
        {
          index:5,
          title: "襄州：“智慧之眼”守护师生“舌尖上的安全”",
          flag: true,
          src: require("../img/pic/title/15.png"),
          date: "2022年6月13日",
          text: "本报讯（全媒体记者 胡采棣 秦小芳 实习生 杨雨）通过安装在学校食堂操作间的高清摄像头，配餐制作的全部流程一览无余；依托先进的物联网感知设备，仓储、配送、烹饪等各个环节的异常情况即时传入“一网统管”系统后台；将AI智能算法植入监控系统，操作人员体温异常、未戴口罩等细节都能精准捕捉、实时预警……6月10日，在襄州区城运中心记者看到，在“互联网+明厨亮灶”特色应用场景中，工作人员可借助智慧化“食堂之眼”，随时随地了解全区8所学校食堂的运转情况，全面排查风险漏洞、纠正不规范行为，切实守护师生“舌尖上的安全”。",
        },
        {
           index:6,
          title: "校园食品安全监管平台纳入政府“一网统管”",
          flag: true,
          src: require("../img/pic/title/12.jpg"),
          date: "2022年6月10日",
          text: "6月10日，襄阳市校园“明厨亮灶”特色应用场景现场会在襄州区城市运行管理中心指挥大厅成功举行。襄阳市人大常委会副主任吉虹、襄州区人大常委会主任张双成，各县（市、区）、开发区分管领导及城运中心主任共60多名领导出席。",
        },
        {
           index:7,
          title: "湖北省委政策研究室领导莅临公司调研",
          flag: true,
          src: require("../img/pic/title/13.jpg"),
          date: "2022年6月9日",
          text: "6月9日上午，湖北省委政策研究室王耀辉巡视员率相关工作人员，莅临公司总部，就生产经营情况进行专题调研。调研组领导参观了AI大数据实验室和物联网实验室，听取了相关情况汇报。",
        },
         {
           index:8,
          title: "襄阳市政协一线协商进校园，守护学生“舌尖上的安全”",
          flag: true,
          src: require("../img/pic/title/14.jpg"),
          date: "2022年6月5日",
          text: "食品安全事关校园安全大局，围绕校园食堂拉紧安全之弦，是保障社会稳定和谐、群众家庭幸福的重要之举。按照《襄阳市学校食堂“互联网+明厨亮灶”工作实施方案》的要求，到2022年底前，全市50%以上的学校食堂要完成“互联网+明厨亮灶”建设改造工作并纳入襄阳市学校食品安全监管服务",
        },
        {
           index:9,
          title: "湖北省教育厅学校后勤办专题调研校园食品安全监管平台运行、维护情况",
          flag: true,
          src: require("../img/pic/title/16.jpg"),
          date: "2022年3月30日",
          text:
            "2022年3月30日，湖北省教育厅学校后勤办吴处长率相关调研组专题调研校园食品安全监管平台运行、维护情况",
        },
        {
          index: 10,
          title: "湖北电视台专题报道！",
          flag: true,
          src: require("../img/pic/title/1.jpg"),
          date: "2021年11月20日",
          text: "近年来，学校食堂服务保障学生不到位问题不断进入到人们的视野，襄阳市纪委监委立足职责定位，把中小学校食堂服务保障学生不到位问题作为教育领…",
        },
        {
          index: 11,
          title: "全面推进！接入银针平台！",
          flag: true,
          src: require("../img/pic/title/2.jpg"),
          date: "2021年11月20日",
          text: "以习近平总书记关于食品安全“四个最严”为指导，深入贯彻落实党和国家关于食品安全的相关文件要求，强化学校食品安全主体责任的落实，充分运用…",
        },
        {
          index: 12,
          title: "东津新区：学校食堂“互联网+明厨亮灶”推进会召开",
          flag: false,
          src: require("../img/pic/title/3.jpg"),
          date: "2021年11月19日",
          text: "11月19日上午，东津新区召开了学校食堂“互联+网明厨亮灶”推进会，会议由区教育办主任胡勇主持，全区中小学…",
        },
        {
          index: 13,
          title: "我市100所试点学校今年底将实现“互联网＋明厨亮灶",
          flag: false,
          src: require("../img/pic/title/4.jpg"),
          date: "2021年11月15日",
          text: "11月11日，全市学校食堂“互联网＋明厨亮灶”工作推进现场会在市三十六中举办。会上透露，今年年底前，全市所有…",
        },
        {
          index: 14,
          title: "肖菊华赴襄阳十堰调研高校创新发展和中小学“双减”工作",
          flag: false,
          src: require("../img/pic/title/18.jpg"),
          date: "2021年11月14日",
          text:
            " 11月9日至11日，副省长肖菊华赴襄阳市、十堰市调研高校创新发展、中小学“双减”和校园安全等工作。 肖菊华先后到武汉理工大学专业学位研究生培养模式改革襄阳示范区项目、中国工程科技十堰研究院、湖北汽车工业学院、湖北医药学院和汉江师范学院，实地调研科教融合、产教融合发展情况，",
        },
        {
          index: 15,
          title: "定了！全市中小学推行！",
          flag: true,
          src: require("../img/pic/title/6.jpg"),
          date: "2021年11月12日",
          text: "校园食品安全事关重大，襄阳出手了。昨日，襄阳市学校食堂“互联网＋明厨亮灶”工作推进现场会在襄阳三十六中高中部举行，襄阳市市直学校校长...",
        },
        {
          index: 16,
          title: "2021年襄阳市学校食堂“互联网+明厨亮灶”工作推进现场会今日召开",
          flag: true,
          src: require("../img/pic/title/7.jpg"),
          date: "2021年11月11日",
          text: "为深入贯彻党和国家关于校园食品安全管理的相关文件指示精神，进一步推进我市“互联网+明厨亮灶”工程建设。11月11日，由市教育局、市市场监管局主办的2021年襄阳市学校食堂“互联网+明厨亮灶”工作推进现场会在市三十六中成功召开。",
        },
        {
          index: 17,
          title:
            "襄阳举办学校食堂“互联网+明厨亮灶”工作推进现场会 2025年底实现全市全面覆盖",
          flag: false,
          src: require("../img/pic/title/8.png"),
          date: "2021年11月11日",
          text: "湖北日报客户端讯（全媒体记者周升蕾 通讯员陈建波 熊卫国）今日，襄阳市学校食堂“互联网＋明厨亮灶”工作推进现场会在襄阳三十六中高中部举行，此次会议由襄阳市教育局和襄阳市市场监督管理局联合主办，襄阳市市直学校校长、总务主任等140人参会。",
        },
        {
          index: 18,
          title: "李莉要求高标准推进“互联网+明厨亮灶”平台建设",
          flag: false,
          src: require("../img/pic/title/9.jpg"),
          date: "2021年7月30日",
          text: "7月27日，市委常委、副市长李莉带领相关部门负责人前往襄阳四中，调研“互联网+明厨亮灶”工作开展情况...",
        },
        {
          index: 19,
          title: "李莉调研“互联网+明厨亮灶”工作开展情况",
          flag: true,
          src: require("../img/pic/title/10.jpg"),
          date: "2021年7月29日",
          text: "近年来，学校食堂服务保障学生不到位问题不断进入到人们的视野，襄阳市纪委监委立足职责定位...",
        },
        {
          index: 20,
          title: "2021年“互联网+明厨亮灶”平台操作培训工作顺利开展",
          flag: true,
          src: require("../img/pic/title/11.jpg"),
          date: "2021年7月2日",
          text: "以习近平总书记关于食品安全“四个最严”为指导，深入贯彻落实党和国家关于食品安全的相关文件要求...",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.dataList = this.newsList.slice(0, 8);
    });

    // console.log( this.dataList)
  },
  methods: {
    changeProduct(index) {
      if ((index = 1)) {
        this.currentIndex++;
      } else {
        this.currentIndex--;
      }
    },
    toProduct(index) {
      this.currentIndex = index + 1;
    },
    mouseover() {
      this.$refs.scrollLine.style.overflowY = "auto";
    },
    mouseleave() {
      this.$refs.scrollLine.style.overflowY = "hidden";
    },
    changeCurrent(val) {
      if (val == 0) {
        this.currentIndex--;
      } else {
        this.currentIndex++;
      }
      localStorage.setItem("index", this.currentIndex);
      this.$router.go(0);
    },
    urlTo() {
      this.dialogVisible = false;

      window.open(this.httpList[this.currentIndex - 1]);
    },
    pageChange(e) {
      this.dataList = this.newsList.slice((e-1)*8, e*8);
    },
    detail(index) {
      // console.log('index',index)
      // console.log(index,888)
      this.$router.push({
        path: "/index/newsDetail",
        name: "newsDetail",
        params: {
          newsIndex: index,
        },
      });
      // this.currentIndex = index
      // this.flag=true
    },
  },
};
</script>

<style lang ="scss" scoped>
.news{
  min-height: calc(100vh - 140px);
}
/deep/.el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.8);
}

/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: white;
}
/deep/ .el-dialog__body {
  text-align: left;
}
/deep/ .el-dialog {
  margin-top: 38vh !important;
}
.newsContainer {
  .date {
    display: none;
  }
  .title {
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    line-height: 33px;
    margin-top: 60px;
    text-align: center;
  }
  .smalltitle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    div:nth-child(1) {
      margin-right: 30px;
    }
    div:nth-child(2) {
      margin-left: 30px;
    }
  }
  p {
    text-indent: 32px;
    text-align: left;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4e4e4e;
    line-height: 2;
  }
  .text {
    /* height: 71px; */
    margin-top: 38px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    video {
      border: 14px solid #ececec;
      border-radius: 10px;
    }
    div {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-top: 30px;
      margin-bottom: 30px;

      img {
        width: 560px;
        /* height: 330px; */
      }
    }
  }
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ffc700 !important;
}
.top {
  width: 100%;
  background: linear-gradient(309deg, #003993 0%, #2a94d2 100%);
  
/* background: linear-gradient(180deg, #3578E5 0%, #4FBEFF 100%); */
  .pic {
    position: relative;
    max-width: 1200px;
    width: 100%;
    height: 26rem;
    margin: 0 auto;
    .left {
      position: absolute;
      top: 9rem;
      left: 0.2rem;
      img {
        width: 34rem;
        height: 100%;
      }
    }
    .right {
      right: 20px;
      bottom: 0;
      /* top:2.2rem; */
      /* margin: 0 auto; */
      position: absolute;
      img {
       width: 18rem;
        height: 16rem;
      }
    }
  }
}

.wonderful {
  width: 100%;
  padding-bottom: 3rem;
  background: #f5f7fa;
  min-height: calc(100vh - 350px);
  .title {
    display: flex;
    flex-direction: column;
    padding-top: 70px;
    div:nth-child(1) {
      font-size: 0.5rem;

      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff9200;
      line-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      /* opacity: 0.6; */
      img:nth-child(1) {
        padding-top: -10px;
        margin-right: 20px;
        margin-bottom: 20rpx;
      }
      img:nth-child(2) {
        margin-left: 30px;
      }
    }
    div:nth-child(2) {
      width: 150px;
      margin: 0 auto;
      margin-top: 30px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      cursor: pointer;
    }
    div:nth-child(2):hover {
      transform: scale(1.02, 1.02);
      color: #0133ff;
    }
  }
  .main {
    padding-top: 0.4rem;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    /* display: flex;
    flex-wrap: wrap; */
    /* justify-content: space-around; */
    /* justify-content: center; */
    .item {
      margin-top: 30px;
      /* width: 100%; */
      /* height: 360px; */
      background: #ffffff;
      border-radius: 8px;
      padding-bottom: 30px;
      position: relative;
      & > img {
        width: 100%;
        /* height: 3rem; */

        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .right {
        /* height: 160px; */
        /* opacity: 0.3; */
        text-align: left;
        padding: 14px 15px 12px;
        position: relative;
        .text {
          margin-top: 6px;
          /* height: 80px; */
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          /* line-height: 20px; */

          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3 !important;
          -webkit-box-orient: vertical;
          margin-bottom: 8px;
        }
        .title {
          padding-top: 0 !important;
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*
          margin: 0 auto;
          /* margin-top:28px; */
          /* width: 260px; */
          /* height: 49px; */
          /* font-size: 16px; */
          font-size: 0.8rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          line-height: 22px;

          color: #333333;
          /* opacity: 0.8; */
        }
        .date {
          width: calc(100% - 30px);
          position: absolute;
          display: flex;
          justify-content: space-between;
          /* bottom: -20px; */
          font-size: 0.7rem;
          font-family: PingFang SC;
          font-weight: 400;
          opacity: 0.8;
          align-items: center;
          .arrow {
            /* position: absolute; */
            display: flex;
            justify-content: flex-end;
            margin-right: 0;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff9200;
            line-height: 20px;
            width: 30px;
            align-items: center;
            /* right: -132px; */
            img {
              min-height: 12px;
              height: 0.75rem;
              width: 0.95rem;
              min-width: 16px;
            }
          }
        }
      }
    }
    .item:hover {
      cursor: pointer;
      opacity: 1;
      /* .right {
        opacity: 1;
        .date {
          .arrow {
            display: block;
          }
        }
      } */
      transform: scale(1.06, 1.1);
      background: #edf5ff;
    }
    .item:nth-child(even) {
      margin-left: 4px;
    }
  }
}
.page {
  /* margin-top: 30px; */
  background: #f5f7fa;
  padding-bottom: 2.1rem;
}


@media screen and (max-width: 770px) {
.wonderful .main .item img{
  height: 16rem;
}
}
@media screen and (max-width: 520px) {
.wonderful .main .item img{
  height: 16rem;
}
.wonderful .main .item{
  padding-bottom: 10px;
  margin-top:10px;
}
.col-lg-3:nth-child(2n){
  padding-left: 7px;
  padding-right: 15px !important;
}
.col-lg-3:nth-child(1n){
  padding-right: 7px;
}
.top .pic .left{
    top: 12rem;
    left: 4.2rem;
  }
}

/* @media screen and (max-width: 780px) {
  .product .main .item {
    height: 14rem !important;
  }
  .wonderful .main .item img{
    height: 16rem;
  }
} */
</style>