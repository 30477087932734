<template>
  <div class="login">
    effw
  </div>
</template>

<script>
export default {
  name:"login",
  data() {
    return {

    };
  },
  created() {
    this.init()
  },
  methods: {
    init(){
      this.form.forEach(v => {
        if(v.children == undefined){
          // console.log(v)
        }

      })

    },
      submitForm(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const res = await this.$http.post('login',this.ruleForm)
            // console.log(res)
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
};
</script>

<style lang ="scss" scoped>
/* .login {
  background: red;
  position: relative;
  width: 100vw;
  height: 100vh;
  .box {
    background: #fff;
    position: absolute;
    left: 50%;
    margin-top: 25%;
    width: 400px;
    height: 300px;
    transform: translate(-50%, -50%);
  }
}
.el-form {
  margin-top: 80px;
  margin-left: 0;
  .el-input {
    width: 250px;
    margin-left: -50px;
  }
} */
</style>