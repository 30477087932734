<template>
  <div class="product" style="background:#F5F7FA">
    <div class="top">
      <div class="pic">
        <div class="left">
          <img src="../img/product/bgtext.png" alt="" />
        </div>
        <div class="right">
          <img src="../img/product/bgimg.png" alt="" />
        </div>
      </div>
    </div>

    <div v-if="detail" class="listContent">
      <div class="item" v-for="(item, index) in productList" :key="index">
        <img :src="item.src" alt="" />
        <div class="title">
          {{ item.title }}
        </div>
      </div>
    </div>

    <div class="content" v-else>
      <div class="left">
        <div class="wrap">
          <div class="title">
            {{ productList[productIndex].title }}
          </div>
          <div class="title1">{{productIndex == 10 || productIndex==11 ||  productIndex==12 || productIndex==13? '引擎介绍':'平台介绍'}}</div>
          <div
            v-for="(item1, index1) in productList[productIndex].text"
            :key="index1"
          >
            <p class="text">
              {{ item1 }}
            </p>
          </div>
          <div class="title1">{{productIndex == 10 || productIndex==11 ||  productIndex==12 || productIndex==13? '引擎优势':'平台优势'}}</div>
          <div
            v-for="(item1, index1) in productList[productIndex].text1"
            :key="index1 + '99'"
          >
            <p class="text">
              {{ item1 }}
            </p>
          </div>
          <div class="pic">
            <img :src="productList[productIndex].src" alt="" />
          </div>

          <div class="changeIndex">
            <div class="bottom">
              <div v-if="productIndex != 0" @click="changeProduct(0)">
                上一篇<span class="hidden-xs" >{{
                  productMain[productIndex - 1].title
                }}</span>
              </div>
            </div>
            <div class="bottom">
              <div class="last" v-if="productIndex != productMain.length - 1" @click="changeProduct(1)">
                下一篇<span class="hidden-xs" >{{
                  productList[productIndex + 1].title
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="right test-1 hidden-xs"
        ref="scrollLine"
        @mouseover="mouseover"
        @mouseleave="mouseleave"
      >
        <div
          v-for="(item, index) in productMain"
          :key="index"
          :class="[productIndex == index ? 'active' : '', 'itemTitle']"
          @click="toProduct(index)"
        >
        <div class="line"></div>
          <div class="title">
            <div class="pic">
              <img :src="item.src" alt="" />
            </div>
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      detail: false,
      productIndex: 0,
      flag: false,
      offsetHeightArr: [],
      offsetTopArr: [],
      productMain: [
        {
          src: require("../img/home/icon/icon1.png"),
          title: "“互联网+明厨亮灶”平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon2.png"),
          title: "智慧校园平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon3.png"),
          title: "教育大数据平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon4.png"),
          title: "心理健康监测平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon5.png"),
          title: "阳光作业平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon6.png"),
          title: "考试仿真系统SAAS平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon7.png"),
          title: "智能题库平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon8.png"),
          title: "舆情分析、监控平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon9.png"),
          title: "排水系统智慧管控平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon10.png"),
          title: "智慧交通、停车平台",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon11.png"),
          title: "手写体识别算法引擎",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon12.png"),
          title: "自适应学习引擎",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon13.png"),
          title: "英语作文机器批阅引擎",
          flag: false,
        },
        {
          src: require("../img/home/icon/icon14.png"),
          title: "英语口语机器评价引擎",
          flag: false,
        },
        // {
        //   src: require("../img/home/icon/icon15.png"),
        //   title: "查看全部产品服务",
        //   flag:false
        // },
      ],
      productList: [
        {
          flag: false,
          src: require("../img/product/pic1.png"),
          title: "“互联网+明厨亮灶”平台",
          text: [
            "平台从管理维度、安全维度、记录维度、监督维度四个方面着手，利用时代赋予的智能算法、大数据分析、云计算、移动互联网、物联网等数字化手段，建立一套科学、智能、合理、公正的阳光厨房解决方案，实施智慧阳光食堂建设；为应用者提高工作效率，为管理者提供管理手段，为监管者提供监管渠道，为食品安全提供保障。",
          ],
          text1: [
            "1.食堂全流程监控，多维度监管，食品溯源、环境卫生、人员健康、行为规范等方面全覆盖，使操作留痕，监管有据；",
            "2.通过核心算法做到无感知监管，出现问题实时反馈，及时预警；",
            "3.提供多样的公示、监管渠道，数据层层上传，实现各界信息互通，实现科学、在线、共同监管，提升社会共治；",
            "4.高效提升监管效率和监管品质，促进完善食品安全监管标准，规范工作流程，从而形成提前预防、过程监督、结果回溯的闭环式管理机制和大数据覆盖的数字化、智能化、透明化的现代化管理模式。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic2.png"),
          title: "智慧校园平台",
          text: [
            "智慧校园定位于为学校、教育工作者、家长、学生提供开放的、可扩展的、可持续服务的校园智慧综合服务平台。智慧校园“以统一平台为基础，以智慧课堂为核心，以智慧管理为支撑，以教师发展为引摩，以平安校园为保障，以和谐家校为纽带，以优势发展为导向”，最终建成均衡发展的智慧校园生态体系。",
          ],
          text1: [
            "1.统一基础平台为支持，学校所有业务平台全部打通，真正实现“业务通”“数据通”“流程通”；",
            "2.智能感知设备的应用，降低管理成本，实现教学、教研、管理效率的全面提升，促进学校优质发展；",
            "3.新一代物联网技术，日常管理、教学、学习的各种数据自动汇聚起来，形成基础数据库，为大数据分析提供基础保障；",
            "4.领导通过大数据分析能直观了解学校整体情况，为宏观决策提供辅助支撑。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic3.png"),
          title: "教育大数据平台",
          text: [
            "平台是集“教、学、管、测、评、存、用”于一体的智慧教育管理与监控评价系统，主要基于智慧考试、智慧课堂、智慧作业等系列产品得出的基础数据，利用云计算、大数据分析等新技术，生成各种统计数据，让教育主管部门简单、科学地完成学情质量分析、教学质量跟踪、试题质量分析、教学质量评估、综合能力分析等报告，从而解决教育信息隐形化、决策粗放化、资源缺失等难题，提高教学质量与综合竞争力。",
          ],
          text1: [
            "1.统一用户管理，针对多角色授权，支持智能导入数据，数据智能化、可视化分析，以及各类报告输出；",
            "2.综合能力评测分析，评测学生的学业、体能、监控等多个维度；",
            "3.全面的学情评测分析，包含市、区、县区域学生的学业评测，趋势走向预警，学校内外纵横对比等;",
            "4.打破数据孤岛，构建教育数据的采集、分析、存储、应用完整闭环，用数据支持科学教育决策。",
          ],
        },
        {
          src: require("../img/product/pic4.png"),
          title: "心理健康监测平台",
          text: [
            "通过AI心理分析引擎、摄像头及智慧问卷方式，对在校生行为进行识别、监督，针对校园内某些危险行为，有效检测、识别、预警、预防，对有厌学、心理抑郁、网瘾等倾向或问题的青少年进行分类管控。",
          ],
          text1: [
            "1.授权用户权限下，平台内所有业务数据互通，使用便捷高效，保证数据私密安全；",
            " 2.通过智能感知设备及智能算法，“人防、物防、技防”三维度结合，防范级别高，有效检测、识别、预警潜在危险行为，大大提高校园有效防护等级，促进学校优质发展；",
            " 3.大数据看板可视化输出，反应迅速，可看到实时提交的测评报告状况及预警信息；",
            "4.多项指标综合分析测评，为科学地分析和诊断学生的心理行为提供更多参考依据。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic5.png"),
          title: "阳光作业平台",
          text: [
            "在教育部发布的“双减”政策下，为区域、学校提供了解教室作业布置内容、学生作业时长等情况的渠道。通过AI智能批阅，帮助教师完成全批全改任务，为学生提供个性化作业方案，提高日常作业效率，达到减负增效的目的。",
          ],
          text1: [
            "1.同步教材教辅，多科目作业支持AI自动批阅，错题自动进入学生错题本；",
            " 2.讲评模式下作业可根据不同区域的教学特点，制定相对应的课程，满足本地化、差异化学习需求，让辅导更有针对性；",
            "3.“后疫情时代”的云端作业，支持学生云端提交作业，教师可在线批阅，实现无接触作业模式；",
            " 4.大数据看板帮助精准快速了解作业布置及反馈情况，助力教育者精准教研教学。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic6.png"),
          title: "考试仿真系统SAAS平台",
          text: [
            "集出国留学考试及国内K12考试阅卷系统为一体，涵盖了从出题到制作答题卡、报名、在线作答、纸笔作业、AI批阅、手工批阅、多维度分析报告等的全流程解决方案，方便快捷，助力高效完成考试测评。",
          ],
          text1: [
            "1.支持在线作答、纸笔作答模式；",
            " 2.所见即所得的答题卡制作方式；",
            "3.多科目多题型AI智能批阅；",
            "4.多维度分析报告，最优学习路径规划。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic7.png"),
          title: "智能题库平台",
          text: [
            "智能题库内包含K12所有学科科目题目、试卷、课件、微课共计两千万套，题目来源于互联网精品资源、历年真题、教辅资源、名校名师资源等。每年资源库会进行更新，更新数量保持在5%~10%区间内，每道题目均包含七级维度（知识点、难易度、题型、章节、解析、类型、年份），教师可通过此类维度进行手工组卷与智能组卷，组合完毕的试卷可运用于考试、作业当中。",
          ],
          text1: [
            "1.每道题目均包含七级维度属性，帮助用户快速查找资源，精确定位所需内容；",
            "2.题库题量达两千万道，且每年更新，更新内容包含全国名校名师自主命题等优质资源；",
            "3.组卷功能快速便捷，一键生成答题卡。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic9.png"),
          title: "舆情分析、监控平台",
          text: [
            "平台拥有行业领先的信息采集、语义分析和情感判断等技术，提供短视频监测、图片舆情、评论分析、可视化大屏展示等功能，帮助政企单位实时捕捉负面舆情苗头，避免事态恶化，洞察舆情发展趋势。",
          ],
          text1: [
            "1.百万优质信源，微博、微信、网站、图片、小视频等舆情全覆盖，全面分析舆情传播动态；",
            "2.海量信息中精准定位目标舆情，垃圾过滤和情感分析准确度达90%；",
            "3.重点舆情不遗漏，多端推送及时达，舆情抓取和推送分钟级；",
            "4.支持分信源类型，分地区，分情感进行舆情检索；设置舆情趋势发展、追根溯源、情感分析、地域分布等十大维度，充分发挥数据价值。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic8.png"),
          title: "排水系统智慧管控平台",
          text: [
            "平台基于物联网理念，以GIS地理信息为基础，在线监测、水位预警、模型分析、智慧运维等多种模块，建立长期、有效、动态管理排水管网大量空间数据和属性数据的基础平台，融合排水数字化管理所需的各种业务处理和专业分析模块，形成一个集排水部门各业务单元信息、数据存储管理和决策分析等多种功能于一体的“城市智慧排水信息化平台”。通过构建安全预警平台，提高应急指挥及快速处置能力；提供科学、先进的城市级水力分析模型，全面评估城市排水管网能力；以城市排水设施数据为基础，结合管网监控数据、气象预报、雨情信息，为城市排水规划、防涝预测提供决策依据。",
          ],
          text1: [
            "1.采用信息化手段，结合GIS展示，全面掌握排水系统的运行情况如管网水位、流量、水质、有害气体浓度、泵站运行状态等，保证排水系统安全高效运行;",
            "2.自动报警、预警，提高应急指挥及快速处置能力，使整个城市排水的管线安全运行，可控制，可预测；",
            "3.采用GIS引擎，实现城市区域管网的地理数据展示，提供基本的地图显示、站点导航、预警信息、实时数据等功能；",
            "4.采用国内外专业排水分析模型，以城市排水设施数据为基础，结合管网监控数据、气象、雨情，实时对城市汇水区智能划分、排水管网/管点饱和度分析、排水能力评估，为管网规划、防涝预测提供决策依据。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic10.png"),
          title: "智慧交通、停车平台",
          text: [
            "平台将无线通信技术、移动终端技术、GPS定位技术、GIS技术等综合应用于城市停车位的采集、管理、查询、预订与导航服务，实现对多个停车场的综合管理，管理者可对各停车场内的车位、车流量、收费情况等进行管理，市民可实时更新、查询、预订、导航剩余停车位，从而实现停车位资源利用率的最大化、停车场利润的最大化和车主停车服务的最优化，助力城市智慧停车快速、经济、有效地升级改造。",
          ],
          text1: [
            "1.获得停车场地数据，形成全城停车场“一张网”格局，提供线上公益性服务，便于大众查到停车位，解决部分停车用户的“逃单”问题；",
            "2.通过NB-IoT技术，利用窄带通信的功耗低、覆盖广、密度高优势，使停车设备直接联网，地磁、地锁、充电桩、道闸可以把信息源源不断地传输到网络平台；",
            "3.利用摄像头拍摄车牌或ETC以准确识别车辆身份，记录车辆进出场时间以准确收费，使车辆快速通过，以快速收费，出入口不拥堵。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic33.png"),
          title: "手写体识别算法引擎",
          text: [
            "通过扫描仪扫描学生作答数据后，学生作答的手写体信息会由引擎进行分离，分离后与正确答案进行对比，对比完成后进行判分。",
          ],
          text1: [
            "1.针对英语的ABCD手写体识别准确率达到95%以上；",
            "2.针对数学的数字及简易公式的识别准确率达到95%以上。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic11.png"),
          title: "自适应学习引擎",
          text: [
            "引擎主要包括四个模块：学生模型、领域模型、自适应引擎和接口模块。引擎有着完善的体系构建和领先的AI技术能力，为学生提供便捷的学习工具和个性化的推荐服务，通过对日常的考试作业数据进行针对性分析后，帮助学生快速找到适合自己的学习内容，帮助学生构建系统的知识结构，定制学习路径，提高学习的效率。",
          ],
          text1: [
            "1.根据多个维度的评估分析，全面、准确地提供学习计划和方案；",
            " 2.根据学生能力的成长变化，实时调整推荐内容，动态规划学习路径；",
            "3.帮助学生整理问题，分析问题，有效建立起知识与学习资源之间的网络关联，构建知识体系。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic22.png"),
          title: "英语作文机器批阅引擎",
          text: [
            "基于深度学习、自然语言处理等技术，通过将图片进行OCR识别转化成文本后，对文本进行分析，对单词拼写、语句连贯性、语法等进行纠错并判分。以分析结果为基础依据，从学业成绩、关注点（作文主题）、情感变化等维度，对学生作文表达背后的深层次含义进行分析，从词、语法、逻辑结构、句子的连贯性、内容等五个方面生成评语。",
          ],
          text1: [
            "1.依托K12各个年级英语作文语料数据，结合语法规则与自然语言处理双向融合，有效提升纠错准确性，批改结果与人评拟合度高；",
            "2.评分包含整体点评、分句点评两大模块，涉及词汇、词性、句式、篇章结构等评分项，覆盖学生英语学习全周期，根据各阶段考纲制定相应评分标准；",
            "3.自动纠错、判分，提示相关写作素材与写作模板，有效提高老师的批改工作效率，提高学生的英语写作能力。",
          ],
        },
        {
          flag: false,
          src: require("../img/product/pic12.png"),
          title: "英语口语机器评价引擎",
          text: [
            "依托智能语言技术开发的英语听、说、考、评一体综合解决方案，集合了领先的语音识别、语音评测等核心技术，对学生的口语测试结果进行自动化的综合性评价。引擎支持听说答题、短文朗读、情景问答、情景对话、故事复述等题型，从单词、句子、段落进行多维度英语口语发音纠错，从发音正确度、流畅度、语言运用能力、总评等多个方面综合考察学生的口语水平，整体评价，帮助学生完成自测练习。",
          ],
          text1: [
            "1.提供精准到音素级的发音评估得分，为学习者纠正单词发音提供依据；",
            "2.提供句子中单词发音、完整度、流利度、语调、重音等多重评估维度；",
            "3.提供段落中语句、单词发音、完整度、流利度多重评估维度得分，从测试角度来促进学生英语口语水平的提高；",
            "4.支持漏读、多读、错读三种检测算法，诊断学习者细微处的发音错误，直观呈现学习者的实际发音。",
          ],
        },
      ],
    };
  },
  created() {
    if (this.$route.params.productIndex != undefined) {
      let index = this.$route.params.productIndex;
      if (index == this.productMain.length) {
        this.productIndex = 0;
      } else {
        this.productIndex = index;
      }

    //   this.productItem(index);
      // if(index%2 == 0){
      //   document.documentElement.scrollTop -= 70;
      //   document.body.scrollTop -= 70;
      // }else{
      //   document.documentElement.scrollTop -= 100;
      //   document.body.scrollTop -= 100;
      // }
    }
  },
  mounted() {
       if (this.$route.params.productIndex != undefined) {
     
      this.currentIndex = this.$route.params.productIndex;
       localStorage.setItem('index',this.currentIndex)
      if(this.currentIndex == 1){
        this.$nextTick(() => {
            setTimeout(()=>{
              this.$refs.video.play()
            },500)
        })
      }
    }else{
      this.currentIndex = localStorage.getItem('index')
    }
    // this.$nextTick(() => {
    //   for (var i=0;i<this.productList.length;i++)
    //   {
    //     let offsetHeight = document.getElementById('location'+i).offsetHeight
    //     this.offsetHeightArr.push(offsetHeight)
    //     let offsetTop = document.getElementById('location'+i).offsetTop
    //     this.offsetTopArr.push(offsetTop)
    //   }
    //   window.addEventListener('scroll', this.scroll)
    // })
  },
  destroyed() {
    window.removeEventListener("scroll", this.scroll, false);
  },
  methods: {
    mouseover() {
      this.$refs.scrollLine.style.overflowY = "auto";
    },
    mouseleave() {
      this.$refs.scrollLine.style.overflowY = "hidden";
    },
    toProduct(index) {
      this.productIndex = index;
      // console.log();
    },
    changeProduct(index) {
      if (index == 1) {
        this.productIndex++;
      } else {
        this.productIndex--;
      }
    },
    productItem(index) {
      this.$nextTick(() => {
        const returnEle = document.querySelector("#" + "location" + index);
        // this.productList[index].flag = true;
        // returnEle.scrollIntoView();
      });
    },
    scroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      let arr = [];
      this.offsetTopArr.forEach((v, i) => {
        if (v < scrollTop + 400) {
          this.flag = true;
          // setTimeout(() => {
          //   this.flag = false
          // },5000)
          arr.push(v);
        }
      });
      if (this.productList[arr.length] != undefined) {
        this.productList[arr.length].flag = true;
      }
    },
  },
};
</script>

<style lang ="scss" scoped>
.listContent {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  .item {
    cursor: pointer;
    width: 373px;
    height: 280px;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 40px;
    img {
      width: 100%;
      height: 217px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .title {
      /* width: 171px; */
      text-align: left;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 60px;
      padding-left: 20px;
    }
  }
}
.content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  text-align: left;
  margin-top: 4rem;
  height: auto;
  padding-bottom:2.5rem;
  .wrap {
    margin: 0 2.2rem 2rem 2rem;
  }
  .left {
    flex: 6;
    /* width: 872px; */
    background: #ffffff;
    height: auto;
    .title {
      text-align: center;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #222222;
      line-height: 33px;
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    p {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 30px;
      text-indent: 34px;
    }
    .title1 {
      margin-bottom: 1.05rem;
      margin-top: 2rem;
    }
    .pic {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      margin-bottom: 3.5rem;
      img {
        /* width: 100%; */
        /* height: 239px; */
      }
    }
    .changeIndex {
      display: flex;
      justify-content: space-between;
    }
    .bottom:nth-child(2) {
      display: flex;
      justify-content: flex-end;
    }
    .bottom {
      display: flex;
      /* justify-content: space-between; */
      width: 100%;

      /* div:nth-child(1){
      display: flex;
      justify-content: flex-start;
    } */
      span {
        cursor: pointer;
        margin-left: 16px;
        /* height: 20px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0133FF;
        line-height: 30px;
        
      }
      span:hover{
        text-decoration: underline;
      }
    }
  }
  .test-1::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .test-1::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
  }
  .test-1::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    
    background: #EFF3FA;
  }
  .right {
    background: #ffffff;
    margin-left: 2rem;
    /* width: 286px; */
    flex: 2;
    min-width: 180px;
    overflow-y: hidden;
    height: 600px;
    ::-webkit-scrollbar {
      width: 20px;
      height: 20px;
      background-color: #f5f5f5;
    }
    .itemTitle:hover{
      background: #EDF5FF;
    }
    .itemTitle {
      width: 100%;
      height: 80px;
      background: #ffffff;
      padding-left: 24px;
      display: flex;
      /* justify-content: center; */
      align-items: center;
      cursor: pointer;
      position: relative;

      img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        /* margin-top:12px; */
      }
      .title {
        display: flex;
        .pic {
          margin-top: 2px;
        }
        /* width: 180px; */
        /* height: 25px; */
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
      }
    }
    .active {
      background: #fffaf4 !important;
        .line{
        position: absolute;
        width: 4px;
        right: 1.1rem;
        height: 40px;
        background: #FF9200;
      }
    }
  }
}
h1 {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  /*animation:turn 1s linear infinite;*/
}
.top {
  width: 100%;
  background: linear-gradient(309deg, #003993 0%, #2a94d2 100%);
  
/* background: linear-gradient(180deg, #3578E5 0%, #4FBEFF 100%); */
  .pic {
    position: relative;
    max-width: 1200px;
    width: 100%;
    height: 26rem;
    margin: 0 auto;
    .left {
      position: absolute;
      top: 9rem;
      left: 0.2rem;
      img {
        width: 34rem;
        height: 100%;
      }
    }
    .right {
      right: 20px;
      bottom: 0;
      /* top:2.2rem; */
      /* margin: 0 auto; */
      position: absolute;
      img {
       width: 18rem;
        height: 16rem;
      }
    }
  }
}

@media screen and (max-width: 720px) {
 
}


@media screen and (max-width: 480px) {
   .top .pic .left{
    top: 12rem;
    left: 4.2rem;

  }
.content .left .pic img{
    width: calc(100vw - 100px);
  }
.content{
  margin-top:0;
  /* .left,.right{
     margin: 0 20px;
     margin-right: 20px;
  } */
 .left{
   p{
     font-size: 14px;
   }
   .wrap{
       padding: 0 10px;
       /* width: calc(100% - 20px); */
   }
 }
 /* .pic{
   img{
     width: 100%;
   }
 }
 .right{} */
}
/* .top .pic{
   height: 5.4rem;
   .left{
     top:2.5rem;
   }
   .right{
     top:1.4rem;
   }
} */

}
</style>