<template>
  <div class="foot">
    <!--底部-->
    <div class="detail">
      <div class="left">
        <div>电话：0710-3222001</div>
        <div class="line"> 
          I
        </div>
        <div>邮箱：qujin@kpxai.com</div>
        <div class="line hidden-xs"> 
          I
        </div>
        <div>地址：襄阳市樊城区建设路21号创意园5号楼401室</div>
      </div>
      <div class="right">
        <img src="../img/foot/logo.png" alt="" />
      </div>
    </div>
    <!-- <div class="line">
    
    </div> -->

    <div class="Version">
      <div>
        Copyright © 2020 鲲鹏芯科技有限公司
        <span @click="urlTo" style="cursor: pointer"
          >鄂ICP备2021020431号-1</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {};
  },
  created() {},
  methods: {
    news() {
      this.$router.push({ path: "/index/news" });
    },
    about() {
      this.$router.push({ path: "/index/about" });
    },
    product() {
      this.$router.push({ path: "/index/product" });
    },
    urlTo() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
      // a href="https://beian.miit.gov.cn/" target="open" style="color:999999"
    },
  },
};
</script>

<style lang ="scss" scoped>
.foot {
  width: 100%;
  background: #373b48;
  /* max-width: 1200px; */
  /* height: 160px; */
  margin: 0 auto;
}
/* .logo{
  position: absolute;
  top: 75px;
  right: 0;
  bottom: 0;
} */
.detail {
  position: relative;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding-top: 3rem;
  /* height: 20px; */
  font-size: 0.7rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #d3d3d3;
  line-height: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  div {
    margin-right: 20px;
  }
  .line{
 margin-left: 10px;
 margin-right: 10px;
  }
  .left {
    display: flex;
    flex-wrap: wrap;
    div:nth-child(1) {
     
      line-height: 20px;
    }
    div:nth-child(2) {
 
      line-height: 20px;
    }
    div:nth-child(3) {
      
      line-height: 20px;
    }
    div{
      margin-top:10px;
    }
  }
  .right {
    right: 0;
    position: absolute;
    top:4.5rem;
    /* margin-top:10px; */
  }
}
.Version {
  /* width: 367px; */
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  /* height: 20px; */
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
  /* margin-top: 20px; */
  padding-bottom: 3rem;
  div {
    margin-top: 20px;
    margin-left: 10px;
  }
  span:hover {
    color: royalblue;
  }
}
@media screen and (max-width: 700px) {
  /deep/ .detail {
    font-size: 10px!important;
  }
}


@media screen and (max-width: 400px) {
  .detail{
    padding: 5px 15px;
  }
  .detail .right{
    /* top: 3.5rem; */
    right: 15px;
    width: 66px;
    img{
      width: 100%;
    }
    /* position: static;
    margin-top: 10px; */
  }
  .detail div{
    margin-right: 0;
  }
  .detail .line{
     margin-left: 10px !important;
 margin-right: 10px !important;
  }
  .Version{
    padding-bottom: 30px;
    font-size: 10px;
  }
 
}
</style>