<template>
  <div class="login">
    <!-- <div :class="[$route.name == 'home' && scrollTop<700? 'top topHome' :'top',$route.name !== 'home' && scrollTop<400? 'top topHome' :'top',]"> -->
    <div class="top">
      <div class="nav">
        <div class="topLeft">
          <img src="../img/home/logo.png" alt="" @click="home" />
        </div>
        <ul class="topRight">
          <li :class="[$route.name == 'home' ? 'active' : '']" @click="home">
            <span
              >首页
              <div class="line" v-if="$route.name == 'home'"></div
            ></span>
          </li>
          <li
            :class="[
              $route.name == 'product' ? 'active ' : '',
              $route.name == 'product' && productHover && flag
                ? 'hoverCss'
                : 'hoverCss',
            ]"
            id=""
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
            @click="product"
          >
            <transition name="sub-comments">
              <div class="hoverBox" ref="hover" v-show="isShow">
                <div class="wrap2">
                  <div
                    class="item2"
                    v-for="(item, index) in productList"
                    :key="index"
                  >
                    <div class="listtitle">
                      {{ bigname[index] }}
                    </div>
                    <div
                      class="list"
                      v-for="(item1, index1) in item"
                      :key="index1"
                    >
                      <div class="listname">
                        <div @click.stop="productLocation(item1.index - 1)">
                          {{ item1.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>

            <span class="cpname"
              >产品服务
              <div class="line" v-if="$route.name == 'product'"></div
            ></span>
          </li>
          <li :class="[$route.name == 'news' ? 'active' : '']" @click="news">
            <span
              >新闻资讯
              <div class="line" v-if="$route.name == 'news'"></div
            ></span>
          </li>
          <li :class="[$route.name == 'about' ? 'active' : '']" @click="about">
            <span
              >关于我们
              <div class="line" v-if="$route.name == 'about'"></div
            ></span>
          </li>
          <li :class="[$route.name == '' ? '' : '']" @click="linkTo">
            <span
              >企业邮箱
              <div class="line" v-if="$route.name == ''"></div
            ></span>
          </li>
        </ul>
      </div>
    </div>

    <div class="container">
      <router-view />
    </div>
    <div class="tool">
      <div class="service">
        <div>
          <img src="../img/home/info.png" alt="" />
        </div>
        <div class="phone">
          <div class="number">0710-3222001</div>
          <div class="triangle"></div>
        </div>
      </div>
      <div @click="backtop" class="backtop">
        <img src="../img/home/back.png" alt="" />
      </div>
    </div>
    <div>
      <foot />
    </div>
  </div>
</template>

<script>
import "swiper/dist/css/swiper.css";
import foot from "./footer.vue";
import { mapState } from "vuex";
export default {
  name: "index",
  components: {
    foot,
  },
  data() {
    return {
      isShow: false,
      bigname: ["AI校园", "AI城市", "AI引擎", "AI健康"],
      scrollTop: 0,
      flag: true,
      productHover: false,
      size: "",
      productList: [
        [
          { name: "智慧校园平台", index: "2" },
          { name: "教育大数据平台", index: "3" },
          { name: "阳光作业平台", index: "5" },
          { name: "智能题库平台", index: "7" },
          { name: "考试仿真系统SAAS平台", index: "6" },
        ],
        [
          { name: "舆情分析、监控平台", index: "8" },
          { name: "排水系统智慧管控平台", index: "9" },
          { name: "智慧交通、停车平台", index: "10" },
        ],
        [
          { name: "手写体识别算法引擎", index: "11" },
          { name: "自适应学习引擎", index: "12" },
          { name: "英语作文机器批阅引擎", index: "13" },
          { name: "英语口语机器评价引擎", index: "14" },
        ],
        [
          { name: "心理健康监测平台", index: "4" },
          { name: "互联网+明厨亮灶平台", index: "1" },
        ],
      ],
    };
  },
  created() {},
  mounted() {
    var setRem = function () {
      // UI设计稿的宽度
      var uiWidth = 1200;
      // 移动端屏幕宽度
      var winWidth = document.documentElement.clientWidth;
      // console.log(winWidth);
      if (winWidth > 1200) {
        document.documentElement.style.fontSize = 20 + "px";
        return;
      }
      // 比率
      var rate = winWidth / uiWidth;
      // 设置html元素的字体大小
      document.documentElement.style.fontSize = rate * 20 + "px";
    };

    setRem();
    window.onresize = function () {
      setRem();
    };
    // }
    // this.$nextTick(() => {
    //     window.addEventListener('scroll', this.handleScroll, true)
    //   });
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
    }),
  },
  methods: {
    linkTo(){
      window.open("http://mail.kpxai.com/")
    },
    mouseenter() {
      this.isShow = true;
    },
    mouseleave() {
      // this.$refs.hover.style.display='none'
      this.isShow = false;
    },
    productLocation(index) {
      this.$nextTick(() => {
        this.$refs.hover.style.display = "none";
        this.$router.push({
          path: "/index/productDetail",
          name: "productDetail",
          params: {
            productIndex: index,
          },
        });
        return;
        this.$refs.hover.style.display = "none";
      });
    },
    backtop() {
      let back = setInterval(() => {
        if (document.body.scrollTop || document.documentElement.scrollTop) {
          document.body.scrollTop -= 100;
          document.documentElement.scrollTop -= 100;
        } else {
          clearInterval(back);
        }
      }, 30);
    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop;
      this.scrollTop = scrollTop;
      if (scrollTop > 200) {
        this.toTopShow = true;
      } else {
        this.toTopShow = false;
      }
      if (scrollTop > 500) {
        this.productHover = true;
      } else {
        this.productHover = true;
      }
    },
    home() {
      this.$router.push({ path: "/index/home" });
    },
    product() {
      this.$refs.hover.style.display = "none";
      this.$router.push({ path: "/index/product" });
    },
    news() {
      this.$router.push({ path: "/index/news" });
    },
    about() {
      this.$router.push({ path: "/index/about" });
    },
  },
  watch: {},
};
</script>

<style lang ="scss" scoped>
/* @media only screen and (min-width:1300px) {
  .login{zoom:0.9};
} */
.cpname {
  height: 100%;
}
.sub-comments-leave-active,
.sub-comments-enter-active {
  transition: max-height 0.5s linear;
}
.sub-comments-enter,
.sub-comments-leave-to {
  max-height: 0;
  opacity: 0;
}
.sub-comments-enter-to,
.sub-comments-leave {
  max-height: 400px;
}
.hoverBox {
  cursor: default;
  z-index: 99;
  position: absolute;
  top: 64px;
  background: rgba(255, 255, 255, 1) !important;
  opacity: 1;
  width: 100vw;
  overflow: hidden;
  height: 400px;
  left: 0px;
  .wrap2 {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    /* height: calc(100% + 16px); */
    /* position: relative; */
    left: -10px;
    top: 0;
    /* top:-16px; */
    display: flex;
    justify-content: space-between;
    .item2 {
      text-align: left;
      width: 160px;
      .listtitle {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #fc9000;
        line-height: 25px;
        margin-bottom: 36px;
        margin-top: 40px;
      }
      .listname {
        div {
          cursor: pointer;
          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3a3a3a;
          line-height: 20px;
          margin-bottom: 20px;
        }
      }
      .listname:hover {
        div {
          color: #fc9000;
        }
      }
    }
  }
  .item2:nth-child(1) {
    padding-left: 0px;
  }
  .item2:nth-child(4) {
    margin-right: 0px;
  }
}
.tool {
  opacity: 0.7;
  position: relative;
  img {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin-top: 20px;
  }
  .phone {
    opacity: 1 !important;
    display: none;
    position: absolute;
    right: 60px;
    width: 120px;
    height: 30px;
    border-radius: 3px;
    color: #ffffff;
    background: #333333;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 30px;
    bottom: 10px;
    .triangle {
      position: absolute;
      left: 120px;
      top: 7px;
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-left: 8px solid #333333;
      border-bottom: 8px solid transparent;
    }
  }
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 30px;
  bottom: 50px;
  i {
    font-size: 26px;
  }
  div {
    cursor: pointer;
    flex: 1;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span {
      font-size: 12px;
    }
  }
  .service:hover .phone {
    display: block;
  }
  div:hover {
    transform: scale(1.01, 1.01);
  }
  .backtop {
    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin-top: 20px;
    }
  }
}
.backtop:hover {
  img {
    transform: scale(1.01, 1.01);
  }
}
.active {
  /* font-size: 16px; */
  font-family: PingFang SC;
  font-weight: 600;
  color: #ff9200;
  span {
  }
}
.container {
  margin: 0 auto;
  width: 100%;
}
.top {
  margin: 0 auto;
  width: 100vw;
  position: fixed;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.89);
  height: 3.4rem;
  max-height: 68px;
  min-height: 50px;
  z-index: 9999999;
  .topLeft {
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 120px;
      height: 38px;
    }
    img:hover {
      cursor: pointer;
    }
  }
  .nav {
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    margin: 0 auto;
  }
  .topRight {
    margin-left: calc(100% - 624px);
    opacity: 1;
    margin-right: -26px;
    width: 504px;
    float: right;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 62px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 100%;
    li {
      list-style: none;
      cursor: pointer;
      width: 100px;
      height: 100%;
      span {
        position: relative;
        width: 50px;
      }
      .line {
        position: absolute;
        bottom: -15px;
        width: 26px;
        height: 4px;
        background: #ff9200;
        border-radius: 4px;
        left: calc(50% - 13px);
        right: 0;
      }
      line-height: 68px;
    }
    li:hover {
      color: #ff9200;
    }
    li:last-child {
    }
  }
}
@media screen and (max-width: 1100px) {
.hoverBox{
  top:56px !important;
}
}
@media screen and (max-width: 1000px) {
.hoverBox{
  top:50px !important;
}
}
@media screen and (max-width: 600px) {
  .hoverBox{
  top:36px !important;
}
  .top {
    height: 36px!important;
    min-height: auto;
    .topLeft {
      img {
        width: 70px;
        height: 23px ;
      }
    }
  }
  .top .topRight {
    margin-left: 0px;
    font-size: 12px;
    margin-right: 0;
  }
  .top .topRight li {
    line-height: 70px;
    width: 60px;
    span {
      font-size: 12px;
    }
    .line {
      bottom: -8px;
    }
  }
}
</style>