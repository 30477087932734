<template>
  <div class="news" style="background:#F5F7FA">
    <div class="top">
      <div class="pic">
        <div class="left">
          <img src="../img/news/bgtext.png" alt="" />
        </div>
        <div class="right">
          <img src="../img/news/bgimg1.png" alt="" />
        </div>
        <!-- <img src="../img/news/zx-banner.png" alt="" /> -->
      </div>
    </div>
    <!--精彩-->
    <div class="content" v-if="flag">
      <div class="left1">
        <div class="wrap">
          <div class="newsContainer" v-if="currentIndex == 1">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>家长可实时监控！襄城校园食堂进入“直播”时代</span>
            </div>
            <div class="line"></div>
            <div class="title">
              家长可实时监控！襄城校园食堂进入“直播”时代
            </div>
            <div class="smalltitle">
              <div>来自：  襄城新闻</div>
              <div>时间： 2023年05月31日</div>
            </div>
            <div class="text">
              
              <p>
                近日，襄城区行政审批局大数据中心联合襄城区教育局共同打造的襄城区学校食品安全监管服务平台，通过互联网+明厨亮灶，提高食品安全管理水平，实现学校食品安全监管管理及时、有效、可追溯。
              </p>
              <div>
                <img src="../img/pic/17/20.png" alt="" />
              </div>
              <p>
                通过安全监管系统的实时监控画面，可以实时看到襄城区学校以及辖区幼儿园等后厨对食材采购、存储、清洗、加工、制作、留样、陪餐、消毒、废弃物等12个关键环节，流程一目了然。食堂管理者不但可以在监管平台可以看到，学生及家长也通过扫描一校一码实时监控情况，真正实现舌尖安全看得见。
              </p>
              <p>
                大数据中心通过共享交换系统向明厨亮灶系统提供“互联网+监管 行政处罚行为信息”和“襄阳市学校基本信息”两个数据接口，可以实时查询到学校食堂或供应商的违规处罚信息。
              </p>
              <p>
                同时，充分发挥平台的监管功能，实现违规行为自动识别、自动分类、自动提醒、自动处置以及后厨环境、食材存放、加工过程全流程监管，做到事件自动处置，结果自动汇总，风险自动预警。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词:食品安全,互联网+明厨亮灶</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

<div class="newsContainer" v-if="currentIndex == 10">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>湖北省教育厅学校后勤办专题调研校园食品安全监管平台运行、维护情况</span>
            </div>
            <div class="line"></div>
            <div class="title">
              湖北省教育厅学校后勤办专题调研校园食品安全监管平台运行、维护情况
            </div>
            <div class="smalltitle">
              <div>来自：  湖北鲲鹏芯科技</div>
              <div>时间：2022年3月30日</div>
            </div>
            <div class="text">
              <p>
            2022年3月30日，湖北省教育厅学校后勤办吴处长率相关调研组专题调研校园食品安全监管平台运行、维护情况。
              </p>
              <p>
              调研组领导对平台功能的全面性、运行的可靠性给予了高度评价，特别是自主开发的AI算法具备对海量数据的动态识别、即时分析功能，整体性能省内领先。
              </p>
              <p>
                在试点学校食堂，调研组观摩了平台的实际运用，直观感受了平台管理对食堂食品安全管理流程的严格规范，现场体验了平台管理对食堂安全管理水平的巨大提升。
              </p>
              <p>
                调研组要求平台开发人员，要主动研究校园食品安全监管工作面临的新形势、新任务，深入发掘技术潜力，不断提升平台性能，努力扩大应用范围；要着眼大数据领域前沿成果的应用，做好安全监管与营养配餐的有机结合；要不断强化监管流程，量化管理成果，依靠科技手段，从细节上保证“四个最严”要求落到实处，努力建设“国家级”的示范平台。
              </p>
              <div>
                <img src="../img/pic/title/16.jpg" alt="" />
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词:食品安全,互联网+明厨亮灶</div>
                <!-- <div @click="dialogVisible = true">阅读全文</div> -->
              </div>
            </div>
          </div>

          <div class="newsContainer" v-if="currentIndex == 2">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>湖北鲲鹏芯荣获《2022年度昇腾AI创新大赛》银奖</span>
            </div>
            <div class="line"></div>
            <div class="title">
              湖北鲲鹏芯荣获《2022年度昇腾AI创新大赛》银奖
            </div>
            <div class="smalltitle">
              <div>来自：  湖北鲲鹏芯科技</div>
              <div>时间：2022年9月20日</div>
            </div>
            <div class="text">
              
              <p>
                2022年9月20日，以“数智未来，因你而来”为主题的武汉人工智能·昇腾AI创新大赛湖北赛区总决赛在武汉光谷皇冠假日酒店成功举办。基于昇腾MindX SDK的高速路视频结构化联合方案、基于昇思MindSpore搭建的地事通SaaS服务应用、基于昇思MindSpore的城市变化遥感监测平台、基于昇腾的乘客动态监测预警联合方案等47个优秀的AI创新项目亮相决赛现场，共同展现出“湖北AI实力”。
              </p>
              <p>
                湖北鲲鹏芯经过多轮选拔、系统演示、专家答辩，其设计研发的食品安全监管服务平台喜获大赛银奖。作为唯一进入此次AI创新大赛决赛的襄阳软件企业，与天喻信息、武汉大学、航天宏图等高校、上市企业同台竞技，脱颖而出。大赛专家、学者对湖北鲲鹏芯所研发的食品安全监管服务平台给予了高度评价，认为其具有一定的技术先进性和推广价值。
              </p>
              <div>
                <img src="../img/pic/16/1.png" alt="" />
              </div>
              <div>
                <img src="../img/pic/16/2.png" alt="" />
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词:食品安全,互联网+明厨亮灶</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

           <div class="newsContainer" v-if="currentIndex == 3">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>省市场监管局专题调研社会餐饮及配餐企业的管理</span>
            </div>
            <div class="line"></div>
            <div class="title">
              省市场监管局专题调研社会餐饮及配餐企业的管理
            </div>
            <div class="smalltitle">
              <div>来自：  湖北鲲鹏芯科技</div>
              <div>时间：2022年7月11日</div>
            </div>
            <div class="text">
              
              <p>
            2022年7月11日，省市场监管朱钦炳，副局长邓仲林，（襄州区教育局，市场监管局王迪，襄州区市监局黄建国局长，城运领导陪同）到襄州区城运调研。主要问题集中在社会餐饮及配餐企业的管理。
              </p>
              <p>
              检查组的领导在襄州，对于我们的平台《银针食品安全监管服务系统》高度评价，说可以推广到全省。
              </p>
              <div>
                <img src="../img/pic/15/1.jpg" alt="" />
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词:食品安全,互联网+明厨亮灶</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-if="currentIndex == 4">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>调研中央厨房 助推团餐产业</span>
            </div>
            <div class="line"></div>
            <div class="title">
              调研中央厨房 助推团餐产业
            </div>
            <div class="smalltitle">
              <div>来自：  襄阳市营养学会</div>
              <div>时间：2022年6月20日</div>
            </div>
            <div class="text">
              <div>
                <img src="../img/pic/14/1.jpg" alt="" />
              </div>
              <p>
             6月16日下午，农工党襄阳市委会调研组、襄阳市学生资助和学校后勤服务指导中心和襄阳市营养学会联合召开襄阳市“中央厨房”产业专题调研座谈会。座谈会邀请襄阳多家中央厨房企业代表、部分学校食堂后勤负责人、襄阳市学校食品安全监管服务平台负责人参加。
              </p>
              
              <p>
              座谈会上，调研组听取了中央厨房相关负责人就中央厨房建设、生产经营、产业标准化规模化等方面情况的介绍，并进行了交流座谈，收集了中央厨房产业发展存在的突出问题、主要困难和建议。我市中央厨房坚持“明厨亮灶，公开透明”原则，接受消费者及社会大众进行多方面多角度监督，在食材采购、食材验收、餐具使用、餐具消毒、食品留样、仓库管理、物流运输各个环节，严格执行规范操作，筑牢食品安全防线。
              </p>
              <p>
              座谈会上，襄阳市学校食品安全监管服务平台负责人介绍了智慧食堂系统，根据学校个性化需求定制标准化营养餐方案，并通过大数据分析，引导和管理青少年饮食健康，推进学生营养餐标准化建设。
              </p>
              <p>
                农工党襄阳市委会调研组通过本次座谈，初步了解中央厨房产业链发展现状，将企业在产业发展中的问题和建议带回去，通过深入调研后形成调研报告，为政府决策提供科学参考，促进营养团餐产业有序、规范、标准化发展，助推湖北现代产业体系建设。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词:食品安全,互联网+，明厨亮灶</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-if="currentIndex == 5">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>聚焦城市运行管理“急难愁盼” 探索城市现代治理“襄州方案”</span>
            </div>
            <div class="line"></div>
            <div class="title">
              聚焦城市运行管理“急难愁盼” 探索城市现代治理“襄州方案”
            </div>
            <div class="smalltitle">
              <div>来自： 襄阳襄州网</div>
              <div>时间：2022年6月13日</div>
            </div>
            <div class="text">
              <p>
              为推进全市“一网统管”建设，通过组织开展学习借鉴成效显著的地区经验，形成“比学赶超”良好氛围，6月10日，襄州区城市运行管理中心将已经投入使用的校园“明厨亮灶”特色应用场景向与会者进行了介绍和实际演练，襄阳市人大常委会副主任吉虹，襄州区领导张双成、王宏举、刘德玉、江海军，各县（市、区）、开发区分管领导及城运中心主任参加活动。
              </p>
              <div>
                <img src="../img/pic/13/1.jpg" alt="" />
              </div>
              <p>
              活动中介绍并演练的“明厨亮灶”特色应用场景，从社会各界关注的热点、难点问题——校园食品安全问题切入，通过物联感知、AI算法等大数据技术手段，全面了解和掌握全区学校食堂日常工作开展情况，最终实现快速发现问题、精准解决问题，有效预防食品安全事件发生，从而保障广大师生舌尖上的安全，会上还进行了经验交流。
              </p>
              <div>
                <img src="../img/pic/13/2.jpg" alt="" />
              </div>
              <p>
              “一网统管”城运平台建设，是市委、市政府头号改革工程，是推动高质量发展、创造高品质生活的“城市大脑”，对于城市安全有序运行、推进治理体系和治理能力现代化具有重大意义。目前，襄州区已完成区城运中心和13个镇（街）、经开区城运中心建设，已经实现了数据汇集、系统集成、联勤联动、共享共建。并将加快构建“一屏观全域，一网管全城”的城市治理体系，为推动襄州经济社会高质量发展、创造襄州特色高品质生活提供有力支撑。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词:食品安全,互联网+，明厨亮灶</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>
          
          <div class="newsContainer" v-if="currentIndex == 6">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>襄州：“智慧之眼”守护师生“舌尖上的安全”</span>
            </div>
            <div class="line"></div>
            <div class="title">
              襄州：“智慧之眼”守护师生“舌尖上的安全”
            </div>
            <div class="smalltitle">
              <div>来自： 襄阳日报</div>
              <div>时间：2022年6月13日</div>
            </div>
            <div class="text">
              <p>
               本报讯（全媒体记者 胡采棣 秦小芳 实习生 杨雨）通过安装在学校食堂操作间的高清摄像头，配餐制作的全部流程一览无余；依托先进的物联网感知设备，仓储、配送、烹饪等各个环节的异常情况即时传入“一网统管”系统后台；将AI智能算法植入监控系统，操作人员体温异常、未戴口罩等细节都能精准捕捉、实时预警……6月10日，在襄州区城运中心记者看到，在“互联网+明厨亮灶”特色应用场景中，工作人员可借助智慧化“食堂之眼”，随时随地了解全区8所学校食堂的运转情况，全面排查风险漏洞、纠正不规范行为，切实守护师生“舌尖上的安全”。
              </p>
              <p>
              食品安全责任重于泰山，校园食品安全更是社会、学校、家庭等各方关注的热点、焦点和重点。襄州作为人口大区，全区学校食堂每日接待近10万人次师生就餐。”襄州区城运中心相关负责人介绍，为实现学校食堂的透明化、信息化管理，该区将学校食堂安全纳入“一网统管”大局，全面梳理运营中的风险点和薄弱环节，开发“互联网+明厨亮灶”特色应用场景，有效补短板、强弱项、优管理。
              </p>
              <p>
              针对学校多、分布散，巡查成本高、难度大的特点，该区搭建了全流程可视化的视频监控体系，通过物联感知、AI算法等大数据技术手段，让学校食堂生产、管理的各个细节“一屏可观之”，全面掌握情况、快速发现问题、精准处置风险；针对食堂餐饮运营中人员广、物料多的特点，该区在应用场景中特别设置了人员防控和原料防控，通过架设感应设备、优化监控系统算法等方式，变单一“巡查”功能为“巡查—记录—分析—处置”的功能集成，精准全面记录人员、物料的变动情况，在为后续倒查提供记录数据的同时，输出行为、状态的分析数据，第一时间判定、预警风险；针对食品安全问题群众关切、家长关心的特点，该区在细化职能部门内部监管功能的同时，增设社会外部监督模块，学生家长可以通过扫描食堂悬挂的二维码，同步查看到对外公布点位的监控视频，并进行满意度评价和提交意见建议。
              </p>
              <p>
             “目前，通过该应用场景，我们已发现并处置风险事件、不当操作49个，有效预防食品安全事件发生，保障广大师生‘舌尖上的安全’。未来，我们将进一步把‘互联网+明厨亮灶’特色场景应用到其他社会层面，真正做到让群众受用。”襄州区城运中心相关负责人表示。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词:食品安全,互联网+，明厨亮灶</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-if="currentIndex == 7">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>襄阳市政协一线协商进校园，守护学生“舌尖上的安全”</span>
            </div>
            <div class="line"></div>
            <div class="title">
              襄阳市政协一线协商进校园，守护学生“舌尖上的安全”
            </div>
            <div class="smalltitle">
              <div>来自： 湖北政协</div>
              <div>时间：2022年6月5日</div>
            </div>
            <div class="text">
              <p>
                食品安全事关校园安全大局，围绕校园食堂拉紧安全之弦，是保障社会稳定和谐、群众家庭幸福的重要之举。
              </p>
              <p>
                按照《襄阳市学校食堂“互联网+明厨亮灶”工作实施方案》的要求，到2022年底前，全市50%以上的学校食堂要完成“互联网+明厨亮灶”建设改造工作并纳入襄阳市学校食品安全监管服务平台监督；到2025年底，全市学校食堂“互联网+明厨亮灶”建设工作完成率要达到100%，实现全市“互联网+明厨亮灶”市、县、校三级安全智慧监管平台联通覆盖
              </p>
              <p>
               这项工作推进的如何，还存在哪些问题？能否按时按量完成建设、达到要求？在前期调研和充分沟的基础上，5月26日，襄阳市政协围绕推进“互联网+明厨亮灶”工作开展“协商在一线”活动。
              </p>
              <p>
                活动中，襄阳市政协委员、市直相关单位代表、县（市、区）政协和教育部门、市场监管部门相关负责人深入湖北文理学院附属中学、市第三十六中学等学校食堂内部和市学生资助和学校后勤服务指导中心，详细参观了智能温控、湿度感应、实时监控识别等先进的物联网系统，实际操作了校园食品安全平台，了解学校食堂“互联网+明厨亮灶”示范点建设运行及全市“互联网+明厨亮灶”软件平台运用情况，并在现场召开协商会，就如何推动“互联网+明厨亮灶”工作在全市各学校落实落地，实现市、县、校三级安全智慧监管平台联通覆盖进行了细致探讨。
              </p>
              <p>
               “咱们的‘互联网+明厨亮灶’建成后该怎么监管、由谁监管，对出现的问题将怎么处理？”“由社会配餐的学校，我们是现在是怎么监管的？未来是否会纳入‘互联网+明厨亮灶’平台进行统一监管？”互动交流中，市政协委员李正婷、肖福兰问出了前期调研中大家最关心的问题。
              </p>
              <p>
               “在全市学校‘互联网+明厨亮灶’建设工作推进过程中，市教育局负责全市学校食堂‘互联网+明厨亮灶’建设统筹工作，建立统一的工作机制及数据标准，组织构建、推广、应用全市‘互联网+明厨亮灶’监管平台，协调学校与市场监管部门数据对接工作，督促学校利用平台开展校园食品安全监督管理工作，并对接入和使用情况进行监督。”市教育局相关负责人表示，学校食堂“互联网+明厨亮灶”平台也是市场监管部门做好校园食品安全监督管理工作的重要抓手，将通过开展网上巡查等监管手段，督促学校及时纠正存在问题，并向教育部门进行通报。
              </p>
              <p>
                “除了部门监管，我们还督促各学校落实食品安全主体责任，学校校长(院长、园长)、食品安全负责人及食堂管理相关人员要通过‘互联网+明厨亮灶’平台随时掌握食堂食品安全状况，定期组织开展自查，及时发现和排除风险隐患，定期组织食品安全管理人员和食堂从业人员进行培训，同时建立健全膳食委员会、家委会(家长代表)参与学校食品安全管理工作制度，主动接受社会监督。”市市场监管局相关负责人补充道。
              </p>
              <p>
                截至2022年5月，襄阳市已完成528所校级“互联网+明厨亮灶”系统账号注册，覆盖率41.1%，市直13所学校20个食堂的“互联网+明厨亮灶”建设工作已完成并做到了每日规范运行，有效保障了2万多名学生的食品安全监管。活动当天，结合校方、群众意见，大家初步商定了推进方案和落实举措。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词:食品安全,互联网+，明厨亮灶</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-if="currentIndex == 7">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>校园食品安全监管平台纳入政府“一网统管”</span>
            </div>
            <div class="line"></div>
            <div class="title">
              校园食品安全监管平台纳入政府“一网统管”
            </div>
            <div class="smalltitle">
              <div>来自：湖北鲲鹏芯科技</div>
              <div>时间：2022年6月10日</div>
            </div>
            <div class="text">
              <p>
                6月10日，襄阳市校园“明厨亮灶”特色应用场景现场会在襄州区城市运行管理中心指挥大厅成功举行。襄阳市人大常委会副主任吉虹、襄州区人大常委会主任张双成，各县（市、区）、开发区分管领导及城运中心主任共60多名领导出席。
              </p>
              <div>
                <img src="../img/pic/12/1.jpg" alt="" />
              </div>
              <p>
                现场会的召开，标志着公司自主研发的“校园食品安全监管平台”正式纳入襄州区“一网统管”体系，实现了“互联互通、数据同步、业务协同”是公司发展历程的里程碑事件。
              </p>
              <p>
                现场会展示了“校园食品安全监管平台”的全部功能，介绍了平台运行管理为辖区食品安全监管工作带来的重大提升，展现
                了平台为提高城市管理智能化水平会聚的强大动能。
              </p>
              <div>
                <img src="../img/pic/12/2.jpg" alt="" />
              </div>
              <p>
                演示中，校园食堂食材的源头监管、追溯体系、违章预警、营养食谱、量化评分等特色功能引起了参会领导的高度关注。他们纷纷表示，“校园食品安全监管平台”实现了对物联感知、AI算法等大数据技术手段的综合运用，实现了对食堂“人流、物流、信息流”的动态监管，实现了监管触角的全方位延伸和监管效率的重大提升，是食品安全监管领域的一款突破性产品，技术成熟度高，应用场景广泛，发展前景广阔。
              </p>
              <p>
                城市运行管理“一网统管”是提高城市科学化、精细化、智能化治理水平的重要举措，是运用数字技术推动城市管理手段、管理模式、管理理念创新的重要载体，对促进城市高质量发展、推进城市治理体系和治理能力现代化具有重要意义。
              </p>
              <p>
                5月底，襄州区政府加大“一网统管”项目建设力度，从拟纳入平台的多个应用场景中选择了校园食品安全监管作为建设成果集中展示。为做好相关工作，襄州区城市运行管理中心同时提出多项严苛的技术要求。受领任务后，公司技术团队发挥克难攻坚、连续作战的优良作风，24小时值守提供解决方案。无论是巨型屏幕风格调整、还是现场数据的反馈速度，大到整体页面风格的转换，小至一行代码的修改，技术人员都严格抓好落实，所有细节都做到了万无一失、尽善尽美，襄州区运营管理中心的领导和工作人员深受感动，给予了高度评价。
              </p>
              <p>
                现场会的成功召开，为公司坚持走自主创新之路注入了强大动力，为公司实现内涵发展提供了有力支撑。下一步，公司将坚持以“智慧教育”为抓手，持续围绕学校行政、教学、后勤工作开展产品研发，构建更具市场竞争力的产品体系，为建设襄阳大数据产业链头部企业不懈努力。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词:互联网+，明厨亮灶</div>
                <div></div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 8">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>湖北省委政策研究室领导莅临公司调研</span>
            </div>
            <div class="line"></div>
            <div class="title">
              湖北省委政策研究室领导莅临公司调研
            </div>
            <div class="smalltitle">
              <div>来自：湖北鲲鹏芯科技</div>
              <div>时间：2022年6月9日</div>
            </div>
            <div class="text">
              <p>
                6月9日上午，湖北省委政策研究室王耀辉巡视员率相关工作人员，莅临公司总部，就生产经营情况进行专题调研。
              </p>
              <p>
                调研组领导参观了AI大数据实验室和物联网实验室，听取了相关情况汇报。
              </p>
              <p>
                王耀辉巡视员指出，人工智能和大数据产业知识高度密集，对人才数量、质量要求很高，非常高兴此次能够来具备自主知识产权和核心AI算法的襄阳本土大数据企业调研；湖北鲲鹏芯入驻襄阳以来，紧紧依靠在教育行业深厚的技术积累迅速打开襄阳地区市场，并对周边地市形成辐射，经营态势良好。可见，公司根植教育行业，“单点突破”的定位是准确的；依靠自主创新，构建比较优势的思路是科学的；夯实基础、平稳前行的路径是合理的；牵头构建襄阳市域大数据产业链条的布局是严谨的。勉励公司牢牢扭住“智慧教育”龙头，持续加大研发力度，聚集更多高层人才，实现跨越式发展，努力建成襄阳地区物联网与人工智能深度融合的“名片”，大数据产业链的“亮点”。王耀辉巡视员还表示，省委政策研究室可在政策引导、人才引进上为公司提供帮助。
              </p>
              <p>
                襄阳市樊城区区委常委、宣传部长余兴军陪同调研。
              </p>
              <div>
                <img src="../img/pic/title/13.jpg" alt="" />
              </div>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：调研，互联网+，明厨亮灶</div>
                <div></div>
              </div>
              <!-- <div class="right">
          <div>分享:</div>
          <div>
            <img src="../img/newsdetail/wx.png" alt="" />
            <img src="../img/newsdetail/qq.png" alt="" />
            <img src="../img/newsdetail/wb.png" alt="" />
            <img src="../img/newsdetail/db.png" alt="" />
          </div>
        </div> -->
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 21">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>2021年“互联网+明厨亮灶”平台操作培训工作顺利开展</span>
            </div>
            <div class="line"></div>
            <div class="title">
              2021年“互联网+明厨亮灶”平台操作培训工作顺利开展
            </div>
            <div class="smalltitle">
              <div>来自：湖北鲲鹏芯科技</div>
              <div>时间：2021年7月2日</div>
            </div>
            <div class="text">
              <div>
                <img src="../img/pic/1/1.jpg" alt="" />
                <!-- <img src="../img/newsdetail/new2.png" alt="">
          <img src="../img/newsdetail/new3.png" alt=""> -->
              </div>
              <p>
                为进一步推动学校食堂“互联网+明厨亮灶”平台系统管理应用工作，2021年7月2日，襄阳市后勤办联合市教育局，在湖北文理学院举办“互联网+明厨亮灶”平台操作培训班。
              </p>
              <div>
                <img src="../img/pic/1/2.jpg" alt="" />
                <!-- <img src="../img/newsdetail/new2.png" alt="">
          <img src="../img/newsdetail/new3.png" alt=""> -->
              </div>
              <p>
                各市、县市区、学校后勤办相关负责人、平台操作人员以及食堂从业人员均参加了本次培训，共计240余人。培训内容分为理论操作讲演、答疑和全员上机操作三个部分，确保参加培训人员都能掌握平台的操作要求。
              </p>
              <div>
                <img src="../img/pic/1/3.jpeg" alt="" />
                <!-- <img src="../img/newsdetail/new2.png" alt="">
          <img src="../img/newsdetail/new3.png" alt=""> -->
              </div>
              <p>
                湖北鲲鹏芯科技有限公司为襄阳市“互联网+明厨亮灶”食品安全监管服务平台搭建了银针食品安全监管服务平台，利用AI智能算法、大数据分析、云计算、互联网、物联网等数字化技术手段，全流程、多维度地进行监管，及时预警、解决平台系统发现的食品安全风险及问题，实现实时、高效的“智慧食堂”建设。同时，监管数据能实时传送到各级监管部门，为监管者提供监管渠道，推进校园食品安全社会共治，为食品安全保驾护航。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：培训，互联网+，明厨亮灶</div>
                <div></div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 20">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>李莉调研“互联网+明厨亮灶”工作开展情况</span>
            </div>
            <div class="line"></div>
            <div class="title">李莉调研“互联网+明厨亮灶”工作开展情况</div>
            <div class="smalltitle">
              <div>来自：襄阳市人民政府网、湖北鲲鹏芯科技</div>
              <div>时间：2021年7月29日</div>
            </div>
            <div class="text">
              <p>
                7月27日，市委常委、副市长李莉带领相关部门负责人前往襄阳四中，调研“互联网+明厨亮灶”工作开展情况。
              </p>
              <!-- <div>
          <img src="../img/pic/2/1.jpg" alt="" />
        </div> -->
              <p>
                李莉一行走进襄阳四中食堂，实地察看了“互联网+明厨亮灶”监管平台，认真听取了相关负责人关于平台功能特点、使用情况的介绍，并通过平台演示，查看了学校食堂监控预警情况。
              </p>
              <!-- <div>
          <img src="../img/pic/2/2.jpg" alt="" />
        </div> -->
              <p>
                在随后召开的座谈会上，李莉详细了解了我市“互联网+明厨亮灶”工作推进情况，并与相关部门负责人进行交流探讨，就如何更好地推进“互联网+明厨亮灶”建设提出指导意见和建议。
              </p>
              <p>
                李莉强调，要加强统筹协调，制定标准规范，做好“互联网+明厨亮灶”规划。要立足食品安全监管平台，打破数据对接共享瓶颈，高标准建成城区学校食堂“互联网+明厨亮灶”平台并投入使用，切实保障师生“舌尖上的安全”。要健全工作机制，有序拓宽“互联网+明厨亮灶”监管平台应用范围，加强信息公开，鼓励消费者参与食品安全监督。
              </p>
              <div>
                <img src="../img/pic/2/3.png" alt="" />
              </div>
              <p>
                湖北鲲鹏芯科技有限公司积极响应政策，为襄阳市“互联网+明厨亮灶”食品安全智慧监管工作倾力打造了银针食品安全监管服务平台。
              </p>
              <div>
                <img src="../img/pic/2/4.jpg" alt="" />
              </div>
              <p>
                平台贯彻落实了《中共中央国务院关于深化改革加强食品安全工作的意见》，以《校园食品安全守护行动方案（2020—2022年）》中“五常”“6天”的管理理念为基础进行了设计开发，融合物联网、大数据、人工智能等技术，对食品安全进行多维度、多环节、多渠道的监管，实现各界信息互通，建立一套高效的预警、管理机制，实现科学、长效、实时、共同监管，全面提升学校餐饮质量安全的整体水平，提升社会共治。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：明厨亮灶，食安，监管</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 19">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>李莉要求高标准推进“互联网+明厨亮灶”平台建设</span>
            </div>
            <div class="line"></div>
            <div class="title">李莉要求高标准推进“互联网+明厨亮灶”平台建设</div>
            <div class="smalltitle">
              <div>来自：市场监督管理局</div>
              <div>时间：2021年7月30日</div>
            </div>
            <div class="text">
              <p>
                7月27日，市委常委、副市长李莉带领相关部门负责人前往襄阳四中，调研“互联网+明厨亮灶”工作开展情况。
              </p>
              <div>
                <img src="../img/pic/2/1.jpg" alt="" />
              </div>
              <p>
                李莉（左三）查看襄阳四中食堂“互联网+明厨亮灶”监管平台
                李莉一行走进襄阳四中食堂，实地察看了“互联网+明厨亮灶”监管平台，听取了相关负责人关于平台功能特点、使用情况的介绍，并通过平台演示，查看了学校食堂监控预警情况。
              </p>
              <div>
                <img src="../img/pic/2/2.jpg" alt="" />
              </div>
              <p>
                在随后召开的座谈会上，市市场监管局党组成员、副局长席修璞汇报了“互联网+明厨亮灶”进展情况。为加快食品安全监管体系建设，市市场监管局积极推进“互联网+明厨亮灶”项目，形成了以人脸识别、视频智能分析、物联感知等技术为基础，搭建统一食品安全监管平台的建设思路。
              </p>
              <p>
                目前，市教育局已搭建“互联网+明厨亮灶”监管平台，襄阳市直13所公办学校20个食堂已全部接入。樊城区市场监管局搭建“互联网+明厨亮灶”监管平台已接入60家餐饮服务单位。
              </p>
              <div>
                <img src="../img/pic/2/0.jpg" alt="" />
              </div>
              <p>
                李莉强调，要加强统筹协调，制定标准规范，做好“互联网+明厨亮灶”规划。要立足食品安全监管平台，打破数据对接共享瓶颈，高标准建成城区学校食堂“互联网+明厨亮灶”平台并投入使用，切实保障师生“舌尖上的安全”。要健全工作机制，有序拓宽“互联网+明厨亮灶”监管平台应用范围，加强信息公开，鼓励消费者参与食品安全监督。（市局餐饮科）
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：监控，预警，平台</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 12">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>全面推进！接入银针平台！</span>
            </div>
            <div class="line"></div>
            <div class="title">全面推进！接入银针平台！</div>
            <div class="smalltitle">
              <div>来自:湖北鲲鹏芯科技</div>
              <div>时间：2021年11月20日</div>
            </div>
            <div class="text">
              <div>
                <img src="../img/pic/5/1.jpg" alt="" />
              </div>
              <p>
                以习近平总书记关于食品安全“四个最严”为指导，深入贯彻落实党和国家关于食品安全的相关文件要求，强化学校食品安全主体责任的落实，充分运用互联网、物联网、区块链、大数据等先进技术，提升校园食品安全管理水平，襄阳市开展学校食堂“互联网+明厨亮灶”食品安全智慧监督模式建设。
              </p>
              <p>
                11月11日，由市教育局、市市场监管局主办的2021年襄阳市学校食堂“互联网+明厨亮灶”工作推进现场会在市三十六中成功召开，襄阳市市直学校校长、总务主任等140人参会。
              </p>
              <div>
                <img src="../img/pic/5/2.jpg" alt="" />
              </div>
              <div>
                <img src="../img/pic/5/3.jpg" alt="" />
              </div>
              <p>
                与会人员现场参观了市三十六中食堂操作间和学生用餐场所，详细参观了晨检一体机、温湿度传感器、实时监控识别等先进的物联网系统。食材仓库内，智能温湿度探测器可以实时监测仓库环境状态，提醒管理人员注意食材变质；检测室中，工作人员每天都会使用多功能食品安全快速检测仪，对当天使用的食材进行添加剂和药物残留检测，杜绝变质和不符合标准的食材走上餐桌；留样食品要在专用冰箱（食品留样柜）存放48小时以上，每样食品留样量不少于125克，便于出现问题有依据可检验检测，冰箱上锁，并有专人看管。餐厅大屏幕上，播放着红外高清摄像机采集到的仓库、后厨的实时画面，学生家长也可以通过微信小程序，查看食品加工现场视频，了解学生的饮食情况。
              </p>
              <div>
                <img src="../img/pic/5/4.jpg" alt="" />
              </div>
              <p>
                据介绍，2020年年底起，市市场监管局、市教育局开始将大数据、云计算、人工智能等技术引入市直学校食堂食品安全监管领域，利用安装在学校食堂后厨的物联网感知设备，对食堂食品追溯、储存保鲜、加工制作、消毒、日常检查、食品留样、视频监控等多个环节进行全面监管，让每一个环节都能够留下影像和台账、溯源回查，从根源上保障学生的食品安全。
              </p>
              <p>
                襄阳市教育局副局长刘朝志表示，今年是全市学校食堂“互联网+明厨亮灶”建设工作全面铺开的第一年，为实现2025年全面覆盖的目标起好步、开好头至关重要。截至目前，市直学校13所20个食堂已全部实现“互联网+明厨亮灶”日常运行。根据《襄阳市学校食堂“互联网+明厨亮灶”工作实施方案》，计划至2022年底前，全市50%以上的学校食堂完成“互联网+明厨亮灶”建设改造工作并纳入“襄阳市学校食品安全监管服务平台”监管；到2025年底，全市学校食堂“互联网+明厨亮灶”建设工作完成率达到100%，实现全市“互联网+明厨亮灶”市、县、校三级安全智慧监管平台联通覆盖。
              </p>
              <p>
                对于全市学校食堂“互联网+明厨亮灶”建设推进工作，市教育局要求，各校要想方设法，把网建起来；建章立制，把网用起来；全力推进，让网畅起来！织起一张覆盖全市、全天候全方位的校园食品安全网，用最严谨的标准、最严格的监管、最严厉的处罚、最严肃的问责，保障全市学生舌尖上的安全。
              </p>
              <div>
                <img src="../img/pic/5/5.png" alt="" />
              </div>
              <p>
                会上，湖北鲲鹏芯工作人员现场演示“襄阳市学校食品安全监管服务平台”的使用方法，襄阳三十六中、襄阳一中、襄阳四中等就本校使用“食品安全应用系统”过程中的经验进行交流。
              </p>
              <p>
                襄阳市“互联网+明厨亮灶”食品安全监管服务平台，由湖北鲲鹏芯科技有限公司开发设计并提供技术支持。平台融合了AI智能算法、大数据分析、云计算、互联网、物联网等技术，依靠智能化、信息化的管理工具和设备，实施校园智慧阳光食堂建设。
              </p>
              <p>
                银针食品安全监管服务平台关联了性能强大、功能多元的智能设备，由专业技术人员融合现代化信息技术，对食堂作业、用餐进行全流程、实时监控，多维度地进行食品安全评估、预警，使操作者和管理者能及时发现、解决平台反馈的食品安全风险及问题。同时，平台提供多样的公示、监管渠道，通过互联网、手机APP实时向相关监管部门和社会公众展示，为保障学校食品安全，提升学校食品安全管理水平，打造行政监督和社会监督共治共管平台而不懈努力。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：互联网+，明厨亮灶，平台</div>
              </div>
              <div class="right">
                <div>分享:</div>
                <div>
                  <img src="../img/newsdetail/wx.png" alt="" />
                  <img src="../img/newsdetail/qq.png" alt="" />
                  <img src="../img/newsdetail/wb.png" alt="" />
                  <img src="../img/newsdetail/db.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 18">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span
                >襄阳举办学校食堂“互联网+明厨亮灶”工作推进现场会
                2025年底实现全市全面覆盖</span
              >
            </div>
            <div class="line"></div>
            <div class="title">
              襄阳举办学校食堂“互联网+明厨亮灶”工作推进现场会
              2025年底实现全市全面覆盖
            </div>
            <div class="smalltitle">
              <div>来自:湖北日报</div>
              <div>时间：2021年11月11日</div>
            </div>
            <div class="text">
              <div>
                <img src="../img/pic/4/1.png" alt="" />
                <!-- <img src="../img/newsdetail/new2.png" alt="">
          <img src="../img/newsdetail/new3.png" alt=""> -->
              </div>
              <p>
                湖北日报客户端讯（全媒体记者周升蕾 通讯员陈建波
                熊卫国）今日，襄阳市学校食堂“互联网＋明厨亮灶”工作推进现场会在襄阳三十六中高中部举行，此次会议由襄阳市教育局和襄阳市市场监督管理局联合主办，襄阳市市直学校校长、总务主任等140人参会。
              </p>
              <div>
                <img src="../img/pic/4/2.png" alt="" />
              </div>
              <p>
                为进一步强化学校食品安全主体责任落实，全面提升襄阳市校园食品安全管理水平，襄阳市开展学校食堂“互联网+明厨亮灶”食品安全智慧监督模式建设。根据《实施方案》设定的具体目标，襄阳市将分三个阶段，在2025年底实现全市学校食堂“互联网+明厨亮灶”全面覆盖：
                2021年12月底前，襄阳全市城区寄宿制学校食堂安装部署完毕“互联网+明厨亮灶”视频采集和物联感知终端设备，通过接入互联网与本校、县（市、区）、市级“互联网+明厨亮灶”监管平台无缝对接。
                2022年底前，襄阳全市50%以上的学校食堂完成“互联网+明厨亮灶”建设改造工作，并纳入“襄阳市学校食品安全监管服务平台”监管。
                2025年底，襄阳全市学校食堂“互联网+明厨亮灶”建设工作完成率达到100%，实现全市“互联网+明厨亮灶”市、县、校三级安全智慧监管平台联通覆盖。
              </p>
              <div>
                <img src="../img/pic/4/3.png" alt="" />
              </div>
              <p>
                推进会开始前，与会人员现场参观襄阳三十六中食堂操作区和学生用餐场所，了解学校“食品安全应用系统”的使用情况。食材仓库内，智能温湿度探测器可以实时监测仓库环境状态，提醒管理人员注意食材变质；检测室中，工作人员每天都会使用多功能食品安全快速检测仪，对当天使用的食材进行添加剂和药物残留检测，杜绝变质和不符合标准的食材走上餐桌；餐厅大屏幕上，播放着红外高清摄像机采集到的仓库、后厨的实时画面，学生家长也可以通过微信小程序，查看食品加工现场视频，了解学生饮食情况。
              </p>
              <p>
                襄阳三十六中党委书记、校长李光成介绍，学校一直高度重视学校食堂管理和建设工作，竭力推进“互联网+明厨亮灶”工程，在大数据时代，积极引入互联网技术，提升食堂管理的科学化和信息化水平，努力打造食材安全卫生、管理严格规范、膳食物美价廉、搭配营养科学、令师生满意、让家长放心的食堂。
              </p>
              <p>
                襄阳市教育局副局长刘朝志强调，今年是全市学校食堂“互联网+明厨亮灶”建设全面铺开的第一年，为实现2025年全面覆盖的目标起好步、开好头至关重要。各学校要坚持全市一盘棋思想，明确任务要求，采取有效措施，克服为难情绪，千方百计把网建好，实现学校食堂工作从少数几个人管，到全员、全社会、全过程无死角管，填补以往管理的漏洞，补齐短板，消除食品安全风险，督促学校后勤真正好起来，优起来，使学校成为一个安全之地、放心之地、温馨之地。刘朝志还强调，各校要严格按照建设标准推动项目实施，加强系统操作人员培训，确保建成后的系统有用、可用、会用，花钱必有效，无效必问责。
              </p>
              <p>
                会上，湖北鲲鹏芯工作人员现场演示“襄阳市学校食品安全监管服务平台”的使用方法，襄阳三十六中、襄阳一中、襄阳四中等就本校使用“食品安全应用系统”过程中的经验进行交流。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：食品安全，管理，互联网+</div>

                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 11">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>湖北电视台专题报道！</span>
            </div>
            <div class="line"></div>
            <div class="title">湖北电视台专题报道！</div>
            <div class="smalltitle">
              <div>来自:湖北鲲鹏芯科技</div>
              <div>时间：2021年11月20日</div>
            </div>
            <div class="text">
              <div>
                <video
                  ref="video"
                  autoplay
                  controls
                  src="../img/pic/6/1.mp4"
                ></video>
                <!-- <img src="../img/newsdetail/new2.png" alt="">
          <img src="../img/newsdetail/new3.png" alt=""> -->
              </div>
              <p>
                近年来，学校食堂服务保障学生不到位问题不断进入到人们的视野，襄阳市纪委监委立足职责定位，把中小学校食堂服务保障学生不到位问题作为教育领域专项整治的工作重点，持续推动学校食堂专项整治工作。
              </p>
              <p>
                今年八月份以来，襄阳市集中开展中小学校食堂服务保障学生不到位问题专项整治行动，以有力监督，切实保障广大师生的合法利益不受侵犯，守护师生舌尖上的安全。专项整治中，襄阳市组成三个检查组，对市直13所中小学食堂食品安全进行突击检查，对食堂财务管理，制度落实，财务收支，食材采购，师生伙食同质同价等方面问题深入督查。针对学生家长反映襄阳八中食堂价格偏高问题，襄阳纪委监委、驻市教育局纪检监察组迅速展开调查。
              </p>
              <!-- <div>
          <img src="../img/pic/4/3.png" alt="" />
        </div> -->
              <p>
                为做好中小学食堂服务保障专项整治后半篇文章，襄阳市启动了学校食堂监管服务平台建设，大力开展“互联网+明厨亮灶”建设。截至目前，襄阳13所市直学校食堂已全部实现“互联网+明厨亮灶”日常运行，全市已有359个学校完成食堂账号注册，基础数据信息录入。预计2025年底将实现市、县、校三级学生食堂安全智慧监管平台联通覆盖。
              </p>
              <p>
                为进一步落实学校食品安全主体责任，充分利用互联网技术全面提升校园食品安全管理水平，推动校园食品安全社会共治，我市启动学校食堂“互联网+明厨亮灶”建设工程。湖北鲲鹏芯科技有限公司为此设计开发了“襄阳市学校食品安全监管服务平台”——银针食品安全监管服务系统。
              </p>
              <p>
                平台融合了AI智能算法、大数据分析、云计算、互联网、物联网等现代化信息技术，依靠智能化、信息化的管理工具和设备，对食堂作业、用餐过程进行全流程、实时监控，多环节、多维度地进行食品安全评估、预警，使操作者和管理者能及时发现、解决平台反馈的食品安全风险及问题，实施校园智慧阳光食堂建设。同时，平台提供多样的公示、监管渠道，通过互联网、手机APP实时向相关监管部门和社会公众展示，使监管部门和社会公众能够及时监督、远程监控、零距离监管，积极推动社会共治，有效地防范校园食品安全风险，切实保障师生舌尖上的安全。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：学校，食品安全管理</div>
                <div></div>
              </div>
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 13">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>东津新区：学校食堂“互联网+明厨亮灶”推进会召开</span>
            </div>
            <div class="line"></div>
            <div class="title">
              东津新区：学校食堂“互联+网明厨亮灶”推进会召开
            </div>
            <div class="smalltitle">
              <div>来自:人民资讯</div>
              <div>时间：2021年11月19日</div>
            </div>
            <div class="text">
              <p>
                11月19日上午，东津新区召开了学校食堂“互联网+明厨亮灶”推进会，会议由区教育办主任胡勇主持，全区中小学(幼儿园)校长(园长)、后勤主任参加了会议;区市场监管局、区教育办相关领导出席了会议。
              </p>
              <p>
                会上，通报了《襄阳市学校食堂“互联网+明厨亮灶”工作实施方案》，汉水小学、一实小代表学校做了表态发言，得一集团技术人员对学校食堂食品安全监管服务系统操作培训。
              </p>
              <!-- <div>
          <img src="../img/pic/4/3.png" alt="" />
        </div> -->
              <p>
                目前，东津新区已安装3C智慧监管的校园食堂20家，上传从业人员信息138人，物联感知设备74台，监控设备69台，实现了公办学校“互联网+明厨亮灶”全覆盖，12月底前将通过接入互联网与市级“互联网+明厨亮灶”监管平台无缝对接，走在襄阳市前列。
              </p>
              <p>
                会议要求，一要坚决落实“四个最严”要求，确保师生“舌尖上的安全”;二要进一步压实各方责任，建立健全各级管理责任制和责任追究制，加强学校食品安全自查和整改规范以及质量提升;三要进一步强化培训，组织学习“互联网+明厨亮灶”操作使用手册，严格落实餐饮服务操作规范;四要进一步加强智慧监管，对辖区各类学校食堂的状况做到底数清、情况明，循序渐进地提升“互联网+明厨亮灶”功效。
              </p>
              <p>
                会议指出，校园食品安全是事关人民群众切身利益的大事，关系到千家万户，是全社会关注的焦点。会议强调，各学校要务必做好三个确保。一是提高认识，确保用得好。各学校必须充分认识“互联网+明厨亮灶”建设工作的重要意义和作用，确保能用、会用、好用。二是加强管理，确保管得好。“互联网+明厨亮灶”建设工作是各学校对食品安全日常管理的重要抓手，各位校长要亲自抓，抓实、抓细。督促后勤管理人员录好基础数据，用数字化、智能化管理好食堂。三是合理推进，确保效果好。各校要结合实际，合理推进学校食堂“互联网+明厨亮灶”建设工作，提升校园食品安全管理水平，按照市局要求，做好数据平台无缝对接，确保效果好。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：学校食堂，安全，责任</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
              <!-- <div class="right">
          <div>分享:</div>
          <div>
            <img src="../img/newsdetail/wx.png" alt="" />
            <img src="../img/newsdetail/qq.png" alt="" />
            <img src="../img/newsdetail/wb.png" alt="" />
            <img src="../img/newsdetail/db.png" alt="" />
          </div>
        </div> -->
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 15">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>肖菊华赴襄阳十堰调研高校创新发展和中小学“双减”工作</span>
            </div>
            <div class="line"></div>
            <div class="title">
              肖菊华赴襄阳十堰调研高校创新发展和中小学“双减”工作
            </div>
            <div class="smalltitle">
              <div>来自:湖北日报</div>
              <div>时间：2021年11月14日</div>
            </div>
            <div class="text">
              <p>
                11月9日至11日，副省长肖菊华赴襄阳市、十堰市调研高校创新发展、中小学“双减”和校园安全等工作。
              </p>
              <p>
               肖菊华先后到武汉理工大学专业学位研究生培养模式改革襄阳示范区项目、中国工程科技十堰研究院、湖北汽车工业学院、湖北医药学院和汉江师范学院，实地调研科教融合、产教融合发展情况，要求进一步密切校地合作，立足地方产业优势，加强协同创新，服务地方发展。各高校要落实立德树人根本任务，把党建与教育教学深度融合，坚持特色办学、内涵发展，着力培养高层次应用型人才，推动科研成果转化应用。
              </p>
              <div>
          <img src="../img/pic/title/18.jpg" alt="" />
        </div>
              <p>
                在襄阳市荆州街小学、人民路小学，保康县熊绎中学、寺坪镇小学，十堰市实验中学、重庆路小学，肖菊华仔细察看学校进出通道、教室、操场、食堂，详细了解学校教学安排、课程设计、课后服务等情况，要求全面落实“双减”工作要求，加强“五项管理”，提高课堂教学质量，优化课后服务，着力化解“大班额”。强调要坚决守住安全底线，把校园安全、食品安全、疫情防控等措施落实到位，确保师生身心健康、教育教学有序和校园安全稳定。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：食品安全，智慧监管</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
              <!-- <div class="right">
          <div>分享:</div>
          <div>
            <img src="../img/newsdetail/wx.png" alt="" />
            <img src="../img/newsdetail/qq.png" alt="" />
            <img src="../img/newsdetail/wb.png" alt="" />
            <img src="../img/newsdetail/db.png" alt="" />
          </div>
        </div> -->
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 14">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>我市100所试点学校今年底将实现“互联网＋明厨亮灶”</span>
            </div>
            <div class="line"></div>
            <div class="title">
              我市100所试点学校今年底将实现“互联网＋明厨亮灶”
            </div>
            <div class="smalltitle">
              <div>来自:人民资讯</div>
              <div>时间：2021年11月15日</div>
            </div>
            <div class="text">
              <p>
                11月11日，全市学校食堂“互联网＋明厨亮灶”工作推进现场会在市三十六中举办。会上透露，今年年底前，全市所有学校食堂（含私立学校）将在襄阳市学校食品安全监管服务平台上注册并录入基础信息；今年年底前将完成100所试点学校的“互联网＋明厨亮灶”建设和接入平台任务。
              </p>
              <p>
                2020年年底起，市教育局、市市场监管局开始将大数据、云计算、人工智能等技术引入市直学校食堂食品安全监管领域，利用安装在学校食堂后厨的物联网感知设备，对食堂食品追溯、储存保鲜、加工制作、消毒、日常检查、食品留样、视频监控等七个环节进行全面监管，让每一个环节都能够留下影像和台账、溯源回查，从根源上保障学生的食品安全，降低校园食品安全事故的发生。截至目前，市三十六中、襄阳一中、襄阳四中等13所市直学校的20个食堂已全部实现“互联网＋明厨亮灶”日常运行。“互联网＋明厨亮灶”系统上线运行后，监管人员和社会公众都可以通过手机APP或小程序实时查看学校食堂后厨环境卫生、食品加工操作情况。
              </p>
              <!-- <div>
          <img src="../img/pic/4/3.png" alt="" />
        </div> -->
              <p>
                今年是我市学校食堂“互联网＋明厨亮灶”建设工作全面铺开的一年。根据《襄阳市学校食堂“互联网+明厨亮灶”工作实施方案》，到2022年年底前，全市50%以上的学校食堂完成“互联网＋明厨亮灶”建设改造工作并纳入襄阳市学校食品安全监管服务平台监督；到2025年年底,全市学校食堂“互联网＋明厨亮灶”建设工作完成率达到100%，实现全市“互联网＋明厨亮灶”市、县、校三级安全智慧监管平台联通覆盖。(市局餐饮服务监管科)
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：食品，安全，智慧监管</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
              <!-- <div class="right">
          <div>分享:</div>
          <div>
            <img src="../img/newsdetail/wx.png" alt="" />
            <img src="../img/newsdetail/qq.png" alt="" />
            <img src="../img/newsdetail/wb.png" alt="" />
            <img src="../img/newsdetail/db.png" alt="" />
          </div>
        </div> -->
            </div>
          </div>

          <!-- <div class="newsContainer" v-else-if="currentIndex == 5">
      <div class="date">
        新闻中心<i
          class="el-icon-caret-right"
          style="margin: 0 10px; margin-top: 2px"
        ></i
        ><span>襄阳100所试点学校今年将实现“互联网+明厨亮灶”</span>
      </div>
      <div class="line"></div>
      <div class="title">襄阳100所试点学校今年将实现“互联网+明厨亮灶”</div>
      <div class="smalltitle">
        <div>来自:人民资讯</div>
        <div>时间：2021年11月12日</div>
      </div>
      <div class="text">
        <p>
          【襄阳政府网消息】11月11日，全市学校食堂“互联网+明厨亮灶”工作推进现场会在市三十六中举办。会上透露，今年年底前，全市所有学校食堂（含私立学校）将在襄阳市学校食品安全监管服务平台上注册并录入基础信息；今年年底前将完成100所试点学校的“互联网+明厨亮灶”建设和接入平台任务。
        </p>
        <p>
          当日，与会人员首先现场观摩了市三十六中食堂。在现场看到，食堂工作人员入口处安装有晨检一体机，可对人员进行身份验证、测温，如果是陌生人进入，系统会自动报警。进入食堂，这里设有米面仓库、调料库、药检室、食品安全快速检测室、菜品存放间等，工作人员正在各自区域忙碌着。食堂里安装了多台红外高清摄像机，可全方位、无死角、全覆盖拍摄各个场景。食堂大厅里的电子大屏幕正实时显示食堂各个区域的画面，以及工作人员晨检记录、每日菜谱、消毒记录、留样记录、陪餐记录等信息。市市场监管局真武山监管所负责人徐斌告诉记者，市三十六中食堂食品“生进熟出”，不走回头路，食材储存、清洗、切配、烹饪等区域设置合理，可减少交叉感染。
        </p>
        <p>
          市学生资助和学校后勤服务指导中心主任散国伟介绍，2020年底起，市教育局、市市场监管局开始将大数据、云计算、人工智能等技术引入到市直学校食堂食品安全监管领域，利用安装在学校食堂后厨的物联网感知设备对食堂食品追溯、储存保鲜、加工制作、消毒、日常检查、食品留样、视频监控等七个环节进行全面监管，形成每一个环节都能够留下影像和台账，溯源回查，从根源上保障学生的食品安全，降低校园食品安全事故的发生。截至目前，市三十六中、襄阳一中、襄阳四中等13所市直学校的20个食堂已全部实现“互联网+明厨亮灶”日常运行。这20个学校食堂后厨从过去的“闲人免进”到“晒”到网上，从学校管理和部门监管拓展到包括学生家长在内的社会公众共同监督，有效提升了学校食堂食品安全治理能力和治理水平。“互联网+明厨亮灶”系统上线运行后，监管人员和社会公众都可以通过手机APP或小程序实时查看学校食堂后厨环境卫生、食品加工操作情况，从少数监管部门监管转变为多数人实时监督。
        </p>
        <p>
          今年是我全市学校食堂“互联网+明厨亮灶”建设工作全面铺开的一年。根据《襄阳市学校食堂“互联网+明厨亮灶”工作实施方案》，到2022年底前，全市50%以上的学校食堂完成“互联网+明厨亮灶”建设改造工作并纳入襄阳市学校食品安全监管服务平台监督；到2025年底，全市学校食堂“互联网+明厨亮灶”建设工作完成率达到100%，实现全市“互联网+明厨亮灶”市、县、校三级安全智慧监管平台联通覆盖。对于全市学校食堂“互联网+明厨亮灶”建设推进工作，市教育局副局长刘朝志要求，各校要想方设法，把“网”建起来；建章立制，把“网”用起来；全力推进，让“网”畅起来。
        </p>
      </div>
      <div class="bottom">
        <div class="left">
          <div>关键词：教育，重点，学习</div>
          <div @click="dialogVisible = true">阅读全文</div>
        </div>
        <div class="right">
          <div>分享:</div>
          <div>
            <img src="../img/newsdetail/wx.png" alt="" />
            <img src="../img/newsdetail/qq.png" alt="" />
            <img src="../img/newsdetail/wb.png" alt="" />
            <img src="../img/newsdetail/db.png" alt="" />
          </div>
        </div>
        </div>
    </div> -->

          <div class="newsContainer" v-else-if="currentIndex == 16">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>定了！全市中小学推行！</span>
            </div>
            <div class="line"></div>
            <div class="title">定了！全市中小学推行！</div>
            <div class="smalltitle">
              <div>来自:襄阳网</div>
              <div>时间：2021年11月12日</div>
            </div>
            <div class="text">
              <p>校园食品安全事关重大，襄阳出手了。</p>
              <p>
                昨日，襄阳市学校食堂“互联网＋明厨亮灶”工作推进现场会在襄阳三十六中高中部举行，襄阳市市直学校校长、总务主任等140人参会。
              </p>
              <p>
                为全面提升校园食品安全管理水平，襄阳市开展学校食堂“互联网+明厨亮灶”食品安全智慧监督模式建设。根据《实施方案》设定的具体目标，襄阳市将分三个阶段，在2025年底实现全市学校食堂“互联网+明厨亮灶”全面覆盖：
              </p>
              <p>
                2021年12月底前，襄阳全市城区寄宿制学校食堂安装部署完毕“互联网+明厨亮灶”视频采集和物联感知终端设备，通过接入互联网与本校、县（市、区）、市级“互联网+明厨亮灶”监管平台无缝对接；
              </p>
              <p>
                2022年底前，襄阳全市50%以上的学校食堂完成“互联网+明厨亮灶”建设改造工作，并纳入“襄阳市学校食品安全监管服务平台”监管；
              </p>
              <p>
                2025年底，襄阳全市学校食堂“互联网+明厨亮灶”建设工作完成率达到100%，实现全市“互联网+明厨亮灶”市、县、校三级安全智慧监管平台联通覆盖。
              </p>
              <p>
                推进会开始前，与会人员现场参观襄阳三十六中食堂操作区和学生用餐场所，了解学校“食品安全应用系统”的使用情况。
              </p>
              <div>
                <img src="../img/pic/10/1.jpeg" alt="" />
              </div>
              <p>
                食材仓库内，智能温湿度探测器可以实时监测仓库环境状态，提醒管理人员注意食材变质；
              </p>
              <p>
                检测室中，工作人员每天都会使用多功能食品安全快速检测仪，对当天使用的食材进行添加剂和药物残留检测，杜绝变质和不符合标准的食材走上餐桌；
              </p>
              <p>
                餐厅大屏幕上，播放着红外高清摄像机采集到的仓库、后厨的实时画面，学生家长也可以通过微信小程序，查看食品加工现场视频，了解学生饮食情况。
              </p>
              <div>
                <img src="../img/pic/10/2.jpeg" alt="" />
              </div>
              <p>
                襄阳三十六中党委书记、校长李光成介绍，在大数据时代，积极引入互联网技术，提升食堂管理的科学化和信息化水平，努力打造食材安全卫生、管理严格规范、膳食物美价廉、搭配营养科学、令师生满意、让家长放心的食堂。
              </p>
              <p>
                襄阳市教育局副局长刘朝志表示，今年是全市学校食堂“互联网+明厨亮灶”建设全面铺开的第一年，为实现2025年全面覆盖的目标起好步、开好头至关重要。各学校要坚持全市一盘棋思想，千方百计把网建好，实现学校食堂工作从少数几个人管，到全员、全社会、全过程无死角管。
              </p>
              <p>
                刘朝志还强调，各校要严格按照建设标准推动项目实施，加强系统操作人员培训，确保建成后的系统有用、可用、会用，花钱必有效，无效必问责。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：学校食堂，安全，监管</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
            </div>
          </div>

          <!-- </div> -->

          <div class="newsContainer" v-else-if="currentIndex == 17">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span
                >2021年襄阳市学校食堂“互联网+明厨亮灶”工作推进现场会今日召开</span
              >
            </div>
            <div class="line"></div>
            <div class="title">
              2021年襄阳市学校食堂“互联网+明厨亮灶”工作推进现场会今日召开
            </div>
            <div class="smalltitle">
              <div>来自:市教育局</div>
              <div>时间：2021年11月11日</div>
            </div>
            <div class="text">
              <div>
                <img src="../img/pic/11/1.jpeg" alt="" />
              </div>
              <p>
                为深入贯彻党和国家关于校园食品安全管理的相关文件指示精神，进一步推进我市“互联网+明厨亮灶”工程建设。11月11日，由市教育局、市市场监管局主办的2021年襄阳市学校食堂“互联网+明厨亮灶”工作推进现场会在市三十六中成功召开。
              </p>
              <div>
                <img src="../img/pic/11/2.jpeg" alt="" />
              </div>
              <p>
                市市场监督管理局副局长席修璞，市教育局党组成员、副局长刘朝志出席本次会议并发言。各县(市)区市场监管局、教育局、学校等单位的分管领导参加本次会议。会议由市学生资助和学校后勤服务指导中心主任散国伟主持。
              </p>
              <div>
                <img src="../img/pic/11/3.jpeg" alt="" />
              </div>
              <p>
                与会人员来到市三十六中食堂的原料存放室、化验室、操作间，详细参观了智能温控、湿度感应、实时监控识别等先进的物联网系统，实际操作了校园食品安全平台，直观感受到了互联网对校园食品安全的全方位实时保障。
              </p>
              <div>
                <img src="../img/pic/11/4.jpeg" alt="" />
              </div>
              <p>
                席修璞指出，食品安全关系人民切身利益，“互联网+明厨亮灶”是食品安全市场监管方式的创新，在各方共同努力下已取得阶段性成果。在今后的工作中，要强化监管力度、落实规章制度，增强责任感、使命感和紧迫感。要压实各方责任，形成齐抓共管的良好局面。要常态化自查，严格落实餐饮服务操作规范。要加强智慧监管，周密部署、统筹推进，不断学习新技术、探索新模式，提高“互联网+明厨亮灶”覆盖率和监管平台接入率。使我市学校“互联网+明厨亮灶”和食品安全工作走在全省前列。
              </p>
              <div>
                <img src="../img/pic/11/5.jpeg" alt="" />
              </div>
              <p>
                刘朝志表示，校园食品安全是社会各方高度关注的大事，关系着学生健康成长，关系着千家万户的幸福，更关系着社会稳定。今年是全市学校食堂“互联网+明厨亮灶”建设工作全面铺开的一年，也是打好基础至关重要的一年。
              </p>
              <p>
                刘朝志要求，要找准短板、加快建设，思想上重视、行动上扎实，细化方案、查漏补缺，稳步推进全市“互联网+明厨亮灶”建设进度。要规范管理，注重运用，加强人员培训，做好平台管理、使用工作，让“网”不仅建起来，更要用起来、管起来、畅起来，提高市学校食品安全监管平台的使用效益。要统筹协调，合力推进，强化部门间沟通协调，落实技术支持、强化资金保障，凝心聚力为学生打造校园食品安全防护网络。
              </p>
              <div>
                <img src="../img/pic/11/6.jpeg" alt="" />
              </div>
              <p>
                散国伟从强化组织、统筹谋划、规范管理、监督考评等几个方面对学校食堂“互联网+明厨亮灶”下一阶段目标作了具体部署。
              </p>
              <p>
                会上，来自市三十六中、市四中、市一中分管校长上台同大家分享了学校在推进“互联网+明厨亮灶”工作的经验做法。技术公司负责人为大家详细讲解演示了全市食品安全管理平台的各类功能。
              </p>
              <div>
                <img src="../img/pic/11/7.jpeg" alt="" />
              </div>
              <p>
                据了解，自今年启动襄阳市学校食堂监管服务平台建设以来，截至目前，市直学校13所20个食堂已全部实现“互联网十明厨亮灶”日常运行，全市完成食堂账号注册、录入基础数据信息的学校食堂有359个。计划至2025年底,实现全市学校食堂“互联网+明厨亮灶”建设工作完成率达到100%，全市
                “互联网+明厨亮灶”市、县、校三级安全智慧监管平台联通覆盖。织起一张覆盖全市、全天候全方位的校园食品安全网，用最严谨的标准、最严格的监管、最严厉的处罚、最严肃的问责，保障全市学生舌尖上的安全。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：食品安全，管理，功能</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>

              <!-- <div class="right">
          <div>分享:</div>
          <div>
            <img src="../img/newsdetail/wx.png" alt="" />
            <img src="../img/newsdetail/qq.png" alt="" />
            <img src="../img/newsdetail/wb.png" alt="" />
            <img src="../img/newsdetail/db.png" alt="" />
          </div>
        </div> -->
            </div>
          </div>

          <div class="newsContainer" v-else-if="currentIndex == 33">
            <div class="date">
              新闻中心<i
                class="el-icon-caret-right"
                style="margin: 0 10px; margin-top: 2px"
              ></i
              ><span>襄阳100所试点学校今年将实现“互联网+明厨亮灶”</span>
            </div>
            <div class="line"></div>
            <div class="title">
              襄阳100所试点学校今年将实现“互联网+明厨亮灶”
            </div>
            <div class="smalltitle">
              <div>来自:人民资讯</div>
              <div>时间：2021年11月12日</div>
            </div>
            <div class="text">
              <p>
                【襄阳政府网消息】11月11日，全市学校食堂“互联网+明厨亮灶”工作推进现场会在市三十六中举办。会上透露，今年年底前，全市所有学校食堂（含私立学校）将在襄阳市学校食品安全监管服务平台上注册并录入基础信息；今年年底前将完成100所试点学校的“互联网+明厨亮灶”建设和接入平台任务。
              </p>
              <p>
                当日，与会人员首先现场观摩了市三十六中食堂。在现场看到，食堂工作人员入口处安装有晨检一体机，可对人员进行身份验证、测温，如果是陌生人进入，系统会自动报警。进入食堂，这里设有米面仓库、调料库、药检室、食品安全快速检测室、菜品存放间等，工作人员正在各自区域忙碌着。食堂里安装了多台红外高清摄像机，可全方位、无死角、全覆盖拍摄各个场景。食堂大厅里的电子大屏幕正实时显示食堂各个区域的画面，以及工作人员晨检记录、每日菜谱、消毒记录、留样记录、陪餐记录等信息。市市场监管局真武山监管所负责人徐斌告诉记者，市三十六中食堂食品“生进熟出”，不走回头路，食材储存、清洗、切配、烹饪等区域设置合理，可减少交叉感染。
              </p>
              <p>
                市学生资助和学校后勤服务指导中心主任散国伟介绍，2020年底起，市教育局、市市场监管局开始将大数据、云计算、人工智能等技术引入到市直学校食堂食品安全监管领域，利用安装在学校食堂后厨的物联网感知设备对食堂食品追溯、储存保鲜、加工制作、消毒、日常检查、食品留样、视频监控等七个环节进行全面监管，形成每一个环节都能够留下影像和台账，溯源回查，从根源上保障学生的食品安全，降低校园食品安全事故的发生。截至目前，市三十六中、襄阳一中、襄阳四中等13所市直学校的20个食堂已全部实现“互联网+明厨亮灶”日常运行。这20个学校食堂后厨从过去的“闲人免进”到“晒”到网上，从学校管理和部门监管拓展到包括学生家长在内的社会公众共同监督，有效提升了学校食堂食品安全治理能力和治理水平。“互联网+明厨亮灶”系统上线运行后，监管人员和社会公众都可以通过手机APP或小程序实时查看学校食堂后厨环境卫生、食品加工操作情况，从少数监管部门监管转变为多数人实时监督。
              </p>
              <p>
                今年是我全市学校食堂“互联网+明厨亮灶”建设工作全面铺开的一年。根据《襄阳市学校食堂“互联网+明厨亮灶”工作实施方案》，到2022年底前，全市50%以上的学校食堂完成“互联网+明厨亮灶”建设改造工作并纳入襄阳市学校食品安全监管服务平台监督；到2025年底，全市学校食堂“互联网+明厨亮灶”建设工作完成率达到100%，实现全市“互联网+明厨亮灶”市、县、校三级安全智慧监管平台联通覆盖。对于全市学校食堂“互联网+明厨亮灶”建设推进工作，市教育局副局长刘朝志要求，各校要想方设法，把“网”建起来；建章立制，把“网”用起来；全力推进，让“网”畅起来。
              </p>
            </div>
            <div class="bottom">
              <div class="left">
                <div>关键词：教育，重点，学习</div>
                <div @click="dialogVisible = true">阅读全文</div>
              </div>
              <!-- <div class="right">
          <div>分享:</div>
          <div>
            <img src="../img/newsdetail/wx.png" alt="" />
            <img src="../img/newsdetail/qq.png" alt="" />
            <img src="../img/newsdetail/wb.png" alt="" />
            <img src="../img/newsdetail/db.png" alt="" />
          </div>
        </div> -->
            </div>
          </div>

          <el-dialog
            title=""
            :visible.sync="dialogVisible"
            :width="dialogWidth"
          >
            <div class="logo">
              <img src="../img/news/logo.png" alt="" />
            </div>
            <div class="wran">
              <img src="../img/news/wran.png" alt="" /> 请注意您的账号和财产安全
            </div>
            <span style="line-height: 30px; font-size: 0.8rem"
              >你即将离去鲲鹏芯官网，去往<span
                style="color: #ea5539; margin-left: 10px"
                >{{ httpList[currentIndex - 1] }}{{ currentIndex }}</span
              ></span
            >
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="urlTo">确 定</el-button>
            </span>
          </el-dialog>

          <div class="changeIndex">
            <div class="bottom">
              <div
                v-if="currentIndex != 0 && currentIndex != 1"
                @click="changeProduct(0)"
              >
                上一篇
              </div>
            </div>
            <div class="bottom">
              <div
                class="last"
                v-if="currentIndex != newsList.length"
                @click="changeProduct(1)"
              >
                下一篇
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="right1 test-1 hidden-xs"
        ref="scrollLine"
        @mouseover="mouseover"
        @mouseleave="mouseleave"
      >
        <div
          v-for="(item, index) in newsList"
          :key="index"
          :class="[currentIndex - 1 == index ? 'active' : '', 'itemTitle']"
          @click="toProduct(index)"
        >
          <div class="line"></div>
          <div class="title">
            <!-- <div class="pic">
              <img :src="item.src" alt="" />
            </div> -->
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <!--精彩-->
    <div class="wonderful" v-else>
      <div class="main">
        <div
          class="item"
          v-for="(item, index) in dataList"
          :key="index + 'p'"
          @click="detail(index + 1)"
        >
          <img :src="item.src" alt="" />
          <div class="right">
            <div>{{ item.title }}</div>
            <div class="text">
              {{ item.text }}
            </div>
            <div class="date">
              <div>{{ item.date }}</div>
              <div class="arrow">查看详情</div>
            </div>
          </div>
        </div>
        <!-- <div class="item" v-for="(item,index) in bottomNews" :key =index @click="detail(index+3)">
          <div class="right"> 
            <div>{{item.title}}</div>
            <div class="date"><div>{{item.date}}</div><div class="arrow"><img src="../img/home/rightArrows.png" alt=""></div></div>
          </div>
          <img :src="item.src" alt="">
        </div> -->
      </div>
    </div>

    <div class="page" v-if="!flag">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="newsList.length"
        :page-size="8"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      dialogWidth: "100px",
      httpList: [
        'https://mp.weixin.qq.com/s/H6wi19bdng2CU_4ljTzGLg',
        'https://m.tech.china.com/tech/article/20220921/092022_1146614.html',
        '',
        'https://mp.weixin.qq.com/s/vKv8VQZcv98Vg8zihnCzzQ',
        'http://xyxz.gov.cn/xwzx/bmdt/202206/t20220613_2832224.shtml',
        'http://m.cnhubei.com/content/2022-06/13/content_14833295.html',
        "",
        "",
        'https://mp.weixin.qq.com/s/J2nrjEIXhX0bmFPoHKb-1Q',
        "",
        "",
        "",
        "https://baijiahao.baidu.com/s?id=1716851355147831341&wfr=spider&for=pc",
        "http://scjg.xiangyang.gov.cn/zwdt/sjyw/202111/t20211115_2634882.shtml",
        'http://jyt.hubei.gov.cn/bmdt/gxhptlm/mtjj/202111/t20211119_3871605.shtml',
        // 'http://www.xiangyang.gov.cn/wzsy/sytt2019/202111/t20211112_2632483.shtml',
        "https://baijiahao.baidu.com/s?id=1716237709894371018&wfr=spider&for=pc",
        "http://jyj.xiangyang.gov.cn/zxzx/jydt/202111/t20211111_2632132.shtml",
        "http://news.hubeidaily.net/web/1625/202111/11/959067.html",
        "http://scjg.xiangyang.gov.cn/zwdt/sjyw/202107/t20210730_2537742.shtml",
        "http://www.xf.gov.cn/zxzx/lddt/202107/t20210729_2536487.shtml",
        "",
      ],
      dialogVisible: false,
      index: 0,
      currentIndex: 1,
      flag: true,
      dataList: [],
      newsList: [
      {
          index:1,
          title: "家长可实时监控！襄城校园食堂进入“直播”时代",
          flag: true,
          src: require("../img/pic/title/20.png"),
          date: "2023年05月31日",
          text: "近日，襄城区行政审批局大数据中心联合襄城区教育局共同打造的襄城区学校食品安全监管服务平台，通过互联网+明厨亮灶，提高食品安全管理水平，实现学校食品安全监管管理及时、有效、可追溯。",
        },
        {
          index:1,
          title: "湖北鲲鹏芯荣获《2022年度昇腾AI创新大赛》银奖",
          flag: true,
          src: require("../img/pic/title/19.jpg"),
          date: "2022年9月20日",
          text: "2022年9月20日，以“数智未来，因你而来”为主题的武汉人工智能·昇腾AI创新大赛湖北赛区总决赛在武汉光谷皇冠假日酒店成功举办。基于昇腾MindX SDK的高速路视频结构化联合方案、基于昇思MindSpore搭建的地事通SaaS服务应用、基于昇思MindSpore的城市变化遥感监测平台、基于昇腾的乘客动态监测预警联合方案等47个优秀的AI创新项目亮相决赛现场，共同展现出“湖北AI实力”",
        },
        {
          index:2,
          title: "省市场监管局专题调研社会餐饮及配餐企业的管理",
          flag: true,
          src: require("../img/pic/14/2.jpg"),
          date: "2022年7月11日",
          text: "2022年7月11日，省市场监管朱钦炳，副局长邓仲林，（襄州区教育局，市场监管局王迪，襄州区市监局黄建国局长，城运领导陪同）到襄州区城运调研。主要问题集中在社会餐饮及配餐企业的管理。",
        },
        {
          index:3,
          title: "调研中央厨房 助推团餐产业",
          flag: true,
          src: require("../img/pic/14/2.jpg"),
          date: "2022年6月20日",
          text: "6月16日下午，农工党襄阳市委会调研组、襄阳市学生资助和学校后勤服务指导中心和襄阳市营养学会联合召开襄阳市“中央厨房”产业专题调研座谈会。座谈会邀请襄阳多家中央厨房企业代表、部分学校食堂后勤负责人、襄阳市学校食品安全监管服务平台负责人参加。",
        },
        {
          index:4,
          title: "聚焦城市运行管理“急难愁盼” 探索城市现代治理“襄州方案”",
          flag: true,
          src: require("../img/pic/13/3.png"),
          date: "2022年6月13日",
          text: "为推进全市“一网统管”建设，通过组织开展学习借鉴成效显著的地区经验，形成“比学赶超”良好氛围，6月10日，襄州区城市运行管理中心将已经投入使用的校园“明厨亮灶”特色应用场景向与会者进行了介绍和实际演练，襄阳市人大常委会副主任吉虹，襄州区领导张双成、王宏举、刘德玉、江海军，各县（市、区）、开发区分管领导及城运中心主任参加活动。",
        },
       {
          index:5,
          title: "襄州：“智慧之眼”守护师生“舌尖上的安全”",
          flag: true,
          src: require("../img/pic/title/15.png"),
          date: "2022年6月13日",
          text: "本报讯（全媒体记者 胡采棣 秦小芳 实习生 杨雨）通过安装在学校食堂操作间的高清摄像头，配餐制作的全部流程一览无余；依托先进的物联网感知设备，仓储、配送、烹饪等各个环节的异常情况即时传入“一网统管”系统后台；将AI智能算法植入监控系统，操作人员体温异常、未戴口罩等细节都能精准捕捉、实时预警……6月10日，在襄州区城运中心记者看到，在“互联网+明厨亮灶”特色应用场景中，工作人员可借助智慧化“食堂之眼”，随时随地了解全区8所学校食堂的运转情况，全面排查风险漏洞、纠正不规范行为，切实守护师生“舌尖上的安全”。",
        },
        {
           index:6,
          title: "校园食品安全监管平台纳入政府“一网统管”",
          flag: true,
          src: require("../img/pic/title/12.jpg"),
          date: "2022年6月10日",
          text: "6月10日，襄阳市校园“明厨亮灶”特色应用场景现场会在襄州区城市运行管理中心指挥大厅成功举行。襄阳市人大常委会副主任吉虹、襄州区人大常委会主任张双成，各县（市、区）、开发区分管领导及城运中心主任共60多名领导出席。",
        },
        {
           index:7,
          title: "湖北省委政策研究室领导莅临公司调研",
          flag: true,
          src: require("../img/pic/title/13.jpg"),
          date: "2022年6月9日",
          text: "6月9日上午，湖北省委政策研究室王耀辉巡视员率相关工作人员，莅临公司总部，就生产经营情况进行专题调研。调研组领导参观了AI大数据实验室和物联网实验室，听取了相关情况汇报。",
        },
         {
           index:8,
          title: "襄阳市政协一线协商进校园，守护学生“舌尖上的安全”",
          flag: true,
          src: require("../img/pic/title/14.jpg"),
          date: "2022年6月5日",
          text: "食品安全事关校园安全大局，围绕校园食堂拉紧安全之弦，是保障社会稳定和谐、群众家庭幸福的重要之举。按照《襄阳市学校食堂“互联网+明厨亮灶”工作实施方案》的要求，到2022年底前，全市50%以上的学校食堂要完成“互联网+明厨亮灶”建设改造工作并纳入襄阳市学校食品安全监管服务",
        },
        {
           index:9,
          title: "湖北省教育厅学校后勤办专题调研校园食品安全监管平台运行、维护情况",
          flag: true,
          src: require("../img/pic/title/1.jpg"),
          date: "2022年3月30日",
          text:
            "2022年3月30日，湖北省教育厅学校后勤办吴处长率相关调研组专题调研校园食品安全监管平台运行、维护情况",
        },
        {
           index:10,
          title: "湖北电视台专题报道！",
          flag: true,
          src: require("../img/pic/title/1.jpg"),
          date: "2021年11月20日",
          text:
            "近年来，学校食堂服务保障学生不到位问题不断进入到人们的视野，襄阳市纪委监委立足职责定位，把中小学校食堂服务保障学生不到位问题作为教育领…",
        },
        
        {
          title: "全面推进！接入银针平台！",
          flag: true,
          src: require("../img/pic/title/2.jpg"),
          date: "2021年11月20日",
          text:
            "以习近平总书记关于食品安全“四个最严”为指导，深入贯彻落实党和国家关于食品安全的相关文件要求，强化学校食品安全主体责任的落实，充分运用…",
        },
        {
          title: "东津新区：学校食堂“互联网+明厨亮灶”推进会召开",
          flag: false,
          src: require("../img/pic/title/3.jpg"),
          date: "2021年11月19日",
          text:
            "11月19日上午，东津新区召开了学校食堂“互联+网明厨亮灶”推进会，会议由区教育办主任胡勇主持，全区中小学…",
        },
        {
          title: "我市100所试点学校今年底将实现“互联网＋明厨亮灶",
          flag: false,
          src: require("../img/pic/title/4.jpg"),
          date: "2021年11月15日",
          text:
            "11月11日，全市学校食堂“互联网＋明厨亮灶”工作推进现场会在市三十六中举办。会上透露，今年年底前，全市所有…",
        },
        // {title:'襄阳100所试点学校今年将实现“互联网+明厨亮灶”',flag:true,src:require('../img/pic/title/5.png'),date:'2021年11月12日'},
        {
          title: "肖菊华赴襄阳十堰调研高校创新发展和中小学“双减”工作",
          flag: false,
          src: require("../img/pic/title/18.jpg"),
          date: "2021年11月14日",
          text:
            " 11月9日至11日，副省长肖菊华赴襄阳市、十堰市调研高校创新发展、中小学“双减”和校园安全等工作。 肖菊华先后到武汉理工大学专业学位研究生培养模式改革襄阳示范区项目、中国工程科技十堰研究院、湖北汽车工业学院、湖北医药学院和汉江师范学院，实地调研科教融合、产教融合发展情况，",
        },
        {
          title: "定了！全市中小学推行！",
          flag: true,
          src: require("../img/pic/title/6.jpg"),
          date: "2021年11月12日",
          text:
            "校园食品安全事关重大，襄阳出手了。昨日，襄阳市学校食堂“互联网＋明厨亮灶”工作推进现场会在襄阳三十六中高中部举行，襄阳市市直学校校长...",
        },
        {
          title: "2021年襄阳市学校食堂“互联网+明厨亮灶”工作推进现场会今日召开",
          flag: true,
          src: require("../img/pic/title/7.jpg"),
          date: "2021年11月11日",
          text: "为深入贯彻党和国家关于校园食品安全管理的相关文件指示精神...",
        },
        {
          title:
            "襄阳举办学校食堂“互联网+明厨亮灶”工作推进现场会 2025年底实现全市全面覆盖",
          flag: false,
          src: require("../img/pic/title/8.png"),
          date: "2021年11月11日",
          text:
            "襄阳市学校食堂“互联网＋明厨亮灶”工作推进现场会在襄阳三十六中...",
        },
        {
          title: "李莉要求高标准推进“互联网+明厨亮灶”平台建设",
          flag: false,
          src: require("../img/pic/title/9.jpg"),
          date: "2021年7月30日",
          text:
            "7月27日，市委常委、副市长李莉带领相关部门负责人前往襄阳四中，调研“互联网+明厨亮灶”工作开展情况...",
        },
        {
          title: "李莉调研“互联网+明厨亮灶”工作开展情况",
          flag: true,
          src: require("../img/pic/title/10.jpg"),
          date: "2021年7月29日",
          text:
            "近年来，学校食堂服务保障学生不到位问题不断进入到人们的视野，襄阳市纪委监委立足职责定位...",
        },
        {
          title: "2021年“互联网+明厨亮灶”平台操作培训工作顺利开展",
          flag: true,
          src: require("../img/pic/title/11.jpg"),
          date: "2021年7月2日",
          text:
            "以习近平总书记关于食品安全“四个最严”为指导，深入贯彻落实党和国家关于食品安全的相关文件要求...",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.setRem();
    window.onresize = function() {
      var that = this;
      that.setRem();
    };
    if (this.$route.params.newsIndex != undefined) {
      this.currentIndex = this.$route.params.newsIndex;
      localStorage.setItem("index", this.currentIndex);
      if (this.currentIndex == 1) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.video.play();
          }, 500);
        });
      }
    } else {
      if (localStorage.getItem("index") != undefined) {
        this.currentIndex = localStorage.getItem("index");
      } else {
        this.currentIndex = 1;
      }
    }
  },
  methods: {
    setRem() {
      // UI设计稿的宽度
      var uiWidth = 1200;
      var that = this;
      // 移动端屏幕宽度
      var winWidth = document.documentElement.clientWidth;
      // console.log(winWidth,99999999,that.dialogWidth)
      if (winWidth < 500) {
        that.dialogWidth = "270px";
        // return
      } else {
        that.dialogWidth = "27rem";
      }

      // console.log(1111,that.dialogWidth)
    },
    changeProduct(index) {
      // console.log(index)
      if (index == 1) {
        this.currentIndex++;
      } else {
        this.currentIndex--;
      }
    },
    toProduct(index) {
      this.currentIndex = index + 1;
      // console.log();
    },
    mouseover() {
      this.$refs.scrollLine.style.overflowY = "auto";
    },
    mouseleave() {
      this.$refs.scrollLine.style.overflowY = "hidden";
    },
    changeCurrent(val) {
      if (val == 0) {
        this.currentIndex--;
      } else {
        this.currentIndex++;
      }
      localStorage.setItem("index", this.currentIndex);
      this.$router.go(0);
    },
    urlTo() {
      this.dialogVisible = false;

      window.open(this.httpList[this.currentIndex - 1]);
    },
    pageChange(e) {
      // console.log(e);
      if (e == 2) {
        this.dataList = this.newsList.slice(8, this.newsList.length);
      } else {
        this.dataList = this.newsList.slice(0, 8);
      }
    },
    detail(index) {
      this.$router.push({
        path: "/index/newsDetail",
        name: "newsDetail",
        params: {
          newsIndex: index,
        },
      });
      // this.currentIndex = index
      // this.flag=true
    },
  },
};
</script>

<style lang="scss" scoped>
video {
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
}
.wran {
  /* display: flex;
  justify-content: center; */
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background: #fcedd5;
  border-radius: 8px;
  margin-bottom: 10px;
  /* opacity: 0.2; */

  color: #333333;
  font-size: 0.95rem;
  img {
    margin-left: 28px;
    margin-right: 18px;
  }
}
.logo {
  display: flex;
  justify-content: center;
  margin-top: 0.55rem;
  margin-bottom: 2rem;
  img {
    width: 120px;
    height: 37px;
  }
}
/deep/.el-dialog__wrapper {
  z-index: 99999999999 !important;
  background: rgba(0, 0, 0, 0.4);
}

/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: white;
}
/deep/ .el-dialog__body {
  text-align: left;
  padding-top: 0;
  padding-bottom: 0;
}
/deep/ .el-dialog {
  margin-top: 38vh;
  /* height: 18rem; */
}
/deep/ .el-dialog__header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /* min-width:200px !important ; */
}
.newsContainer {
  .date {
    display: none;
  }
  .title {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 33px;
    margin-top: 3rem;
    text-align: center;
  }
  .smalltitle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.1rem;
    div:nth-child(1) {
      margin-right: 30px;
    }
    div:nth-child(2) {
      margin-left: 30px;
    }
  }
  p {
    text-indent: 32px;
    text-align: left;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4e4e4e;
    line-height: 2;
  }
  .text {
    /* height: 71px; */
    margin-top: 1.9rem;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    video {
      border: 14px solid #ececec;
      border-radius: 10px;
    }
    div {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      img {
        width: 100%;
        max-width: 600px;
        border-radius: 8px;
        /* height: 330px; */
      }
    }
  }
}
.content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  text-align: left;
  /* margin-top: 80px; */
  height: auto;
  padding-bottom: 2.5rem;
  .wrap {
    margin: 0 2.2rem 2rem 2rem;
  }

  .left1 {
    /* width: 872px; */
    flex: 6;
    background: #ffffff;
    height: auto;
    /* .title {
      text-align: center;
      height: 33px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      line-height: 33px;
      margin-top: 60px;
      margin-bottom: 60px;
    }
    p {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      line-height: 30px;
      text-indent: 34px;
    }
    .title1 {
      margin-bottom: 22px;
      margin-top: 40px;
    }
    .pic {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 60px;
      margin-bottom: 70px;
      img {
        width: 449px;
        height: 239px;
      }
    } */
    .changeIndex {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .bottom:nth-child(1):hover,
    .bottom:nth-child(2):hover {
      text-decoration: underline;
      cursor: pointer;
    }
    .bottom:nth-child(2) {
      display: flex;
      justify-content: flex-end;
      color: #0133ff;
    }
    .bottom {
      /* div:nth-child(1){
        cursor: pointer;
      } */
      .left div:nth-child(2):hover {
        text-decoration: underline;
        cursor: pointer;
      }
      font-size: 16px;
      color: #0133ff;
      display: flex;
      /* justify-content: space-between; */
      width: 100%;

      /* div:nth-child(1){
      display: flex;
      justify-content: flex-start;
    } */
      div {
      }
      .left {
        margin-top: 1.5rem;
        text-align: left;

        div:nth-child(1) {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ff9200;
          /* line-height: 32px; */
        }
        div:nth-child(2) {
          margin-top: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #0133ff;
          line-height: 32px;
          cursor: pointer;
          width: 60px;
          /* margin-bottom: 10px; */
          a {
            list-style: none;
            /* text-decoration: none; */
            color: #ef2f2f;
          }
        }
      }
      .right {
        display: none;
      }
      span {
        cursor: pointer;
        margin-left: 16px;
        /* height: 20px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff9200;
        line-height: 30px;
        text-decoration: underline;
      }
    }
    .page {
      display: none;
    }
  }
  .test-1::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .test-1::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
  }
  .test-1::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
  .right1 {
    background: #ffffff;
    margin-left: 40px;
    /* width: 286px; */
    flex: 2;
    min-width: 180px;
    overflow-y: hidden;
    margin-top: 3rem;
    height: 600px;
    ::-webkit-scrollbar {
      width: 20px;
      height: 20px;
      background-color: #f5f5f5;
    }
    .itemTitle:hover {
      background: #edf5ff;
    }
    .itemTitle {
      height: 80px;
      background: #ffffff;
      padding-left: 24px;
      display: flex;
      /* justify-content: center; */
      align-items: center;
      cursor: pointer;
      position: relative;

      img {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        /* margin-top:12px; */
      }
      .title {
        display: flex;
        .pic {
          margin-top: 5px;
        }
        /* width: 180px; */
        /* height: 25px; */
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 25px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    .active {
      background: #fffaf4 !important;
      .line {
        position: absolute;
        width: 4px;
        right: 0;
        height: 40px;
        background: #ff9200;
      }
    }
  }
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ffc700 !important;
}
.news {
  width: 100%;
  .top {
    width: 100%;
    background: linear-gradient(309deg, #003993 0%, #2a94d2 100%);

    /* background: linear-gradient(180deg, #3578E5 0%, #4FBEFF 100%); */
    .pic {
      position: relative;
      max-width: 1200px;
      width: 100%;
      height: 26rem;
      margin: 0 auto;
      .left {
        position: absolute;
        top: 9rem;
        left: 0.2rem;
        img {
          width: 34rem;
          height: 100%;
        }
      }
      .right {
        right: 20px;
        bottom: 0;
        /* top:2.2rem; */
        /* margin: 0 auto; */
        position: absolute;
        img {
          width: 18rem;
          height: 16rem;
        }
      }
    }
  }
}

.wonderful {
  width: 100%;
  /* height: 658px; */
  background: #f5f7fa;
  .title {
    display: flex;
    flex-direction: column;
    padding-top: 102px;
    div:nth-child(1) {
      font-size: 36px;

      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff9200;
      line-height: 32px;
      /* opacity: 0.6; */
    }
    div:nth-child(2) {
      width: 150px;
      margin: 0 auto;
      margin-top: 30px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      cursor: pointer;
    }
    div:nth-child(2):hover {
      transform: scale(1.02, 1.02);
    }
  }
  .main {
    padding-top: 60px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    .item {
      width: 270px;
      height: 360px;
      background: #ffffff;
      border-radius: 8px;
      margin-right: 40px;
      margin-bottom: 40px;
      & > img {
        width: 270px;
        height: 160px;

        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .right {
        height: 160px;
        /* opacity: 0.3; */
        text-align: left;
        padding: 14px 15px 12px;
        position: relative;
        .text {
          margin-top: 14px;
          /* height: 80px; */
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
        & > div {
          margin: 0 auto;
          /* margin-top:28px; */
          /* width: 260px; */
          /* height: 49px; */
          font-size: 16px;

          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          line-height: 22px;

          color: #333333;
          /* opacity: 0.8; */
        }
        .date {
          position: absolute;
          display: flex;
          justify-content: space-between;
          /* margin-top:26px; */
          /* margin-left: 26px; */
          /* width: 107px; */
          /* height: 14px; */
          bottom: 10px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          opacity: 0.8;
          .arrow {
            position: absolute;
            display: flex;
            justify-content: flex-end;
            margin-right: 0;
            height: 20px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ff9200;
            line-height: 20px;
            right: -132px;
          }
        }
      }
    }

    .item:hover {
      cursor: pointer;
      opacity: 1;
      /* .right {
        opacity: 1;
        .date {
          .arrow {
            display: block;
          }
        }
      } */
      transform: scale(1.06, 1.1);
      background: #fffaf4;
    }
    .item:nth-child(4n + 0) {
      margin-right: 0;
    }
  }
}
.page {
  margin-top: 30px;
  padding-bottom: 44px;
}

@media screen and (max-width: 770px) {
}
@media screen and (max-width: 500px) {
  .news .top .pic .left {
    top: 12rem;
    left: 4.2rem;
  }
  .content .wrap {
    margin: 0 10px 10px;
  }
  .newsContainer .title {
    font-size: 15px;
    line-height: 21px;
    margin-bottom: 16px;
  }
  .newsContainer p {
    font-size: 12px;
    line-height: 28px;
  }
  .newsContainer .text div img {
    width: 345px;
    /* height: 184px; */
  }
  .el-dialog__body {
    span {
      line-height: 24px;
    }
    .wran {
      height: 32px;
    }
  }
  .logo {
    padding-top: 20px;
  }
  /deep/.el-dialog {
    margin-top: 28vh !important;
  }
  .logo img {
    width: 90px;
    height: 30px;
  }
  /deep/.el-dialog__header {
    display: none;
  }
  /deep/ .el-dialog__footer {
    padding: 0px 20px 10px !important;
  }
  .dialog-footer {
    display: flex;
    justify-content: flex-end;

    .el-button {
      width: 50px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
