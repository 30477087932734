<template>
  <div class="amap-page-container">
    <div class="top">
      <div class="pic">
        <div class="left">
          <img src="../img/about/bgtext.png" alt="" />
        </div>
        <div class="right">
          <img src="../img/news/bgimg.png" alt="" />
        </div>
      </div>
    </div>

    <div class="main visible-xs">
      <div class="wrap">
        <div class="list_xs">
          <div class="leftimg" style="float: left; clear: both" align="center">
            <div class="img">
              <img src="../img/about/mengban.png" alt="" />
            </div>
            <!-- <div class="mask"></div> -->
          </div>
          <p>
            湖北鲲鹏芯科技有限公司，核心研发团队组建于2012年，成立于湖北省襄阳市。依托于华中科技大学自动化学院、湖北大学智慧学习研究中心
            、湖北文理学院计算机工程学院，公司拥有自由开放的学术氛围和国际前沿的研究方向，拥有<span>软硬件、算法、加密、安全、接口、协议、环境、工业设计</span>等完整专业工程技术人员。
          </p>
          <p>
            公司主要的研究领域包括：<span>人工智能、大数据、云计算、物联网、移动互联网</span>等。产品涉及<span>软件、物联网设备、自动化设备、系统集成、数字城市、智慧教育、智能建筑、数据中心</span>等。其中，基于NLP（自然语言处理）和Deep-learning（深度学习）的机器批阅技术，已广泛应用于培生中国、新东方、百度教育等相关产品；
            基于人工智能、云计算、大数据的各类政务应用，得到了政府及相关业务部门的认可与应用。
          </p>
          <p>
            公司深耕人工智能、数字化领域，<span>具备相应技术的研究与试验能力，拥有先进、完善的管理体系，坚持以创新驱动发展</span>，通过持续的技术创新突破，致力于为客户提供“多种、多元、多维、多样”的解决方案，坚持打造一流的专业实力与区域品牌，争做行业标杆。
          </p>
          <p>
            截止目前，<span>公司参与超过近百件整体解决方案案例，具备丰富的服务经验</span>。在信息一体化、智慧教育、数字平台、智能建筑等多领域中，以专业、完善的售前、售中和售后服务赢得了客户的信赖和广泛好评。
          </p>
          <p>
            作为<span>襄阳市智慧城市与大数据联合理事会副会长单位</span>，公司在注重主营业务基础上，坚持发展战略，积极融入区域产业链条，协助区域建设发展需要，实现与客户共赢发展。
          </p>
        </div>
      </div>
    </div>

    <div class="main hidden-xs">
      <div class="row wrap">
        
        <div class="col-md-8 col-sm-7 col-lg-8 list1">
          <div class="text" style="text-align: left">
            <p>
              湖北鲲鹏芯科技有限公司，核心研发团队组建于2012年，成立于湖北省襄阳市。依托于华中科技大学自动化学院、湖北大学智慧学习研究中心
              、湖北文理学院计算机工程学院，公司拥有自由开放的学术氛围和国际前沿的研究方向，拥有<span>软硬件、算法、加密、安全、接口、协议、环境、工业设计</span>等完整专业工程技术人员。
            </p>
            <p>
              公司主要的研究领域包括：<span>人工智能、大数据、云计算、物联网、移动互联网</span>等。产品涉及<span>软件、物联网设备、自动化设备、系统集成、数字城市、智慧教育、智能建筑、数据中心</span>等。其中，基于NLP（自然语言处理）和Deep-learning（深度学习）的机器批阅技术，已广泛应用于培生中国、新东方、百度教育等相关产品；
              基于人工智能、云计算、大数据的各类政务应用，得到了政府及相关业务部门的认可与应用。
            </p>
            <p>
              公司深耕人工智能、数字化领域，<span>具备相应技术的研究与试验能力，拥有先进、完善的管理体系，坚持以创新驱动发展</span>，通过持续的技术创新突破，致力于为客户提供“多种、多元、多维、多样”的解决方案，坚持打造一流的专业实力与区域品牌，争做行业标杆。
            </p>
            <p>
              截止目前，<span>公司参与超过近百件整体解决方案案例，具备丰富的服务经验</span>。在信息一体化、智慧教育、数字平台、智能建筑等多领域中，以专业、完善的售前、售中和售后服务赢得了客户的信赖和广泛好评。
            </p>
            <p>
              作为<span>襄阳市智慧城市与大数据联合理事会副会长单位</span>，公司在注重主营业务基础上，坚持发展战略，积极融入区域产业链条，协助区域建设发展需要，实现与客户共赢发展。
            </p>
          </div>
        </div>
        <div class="col-md-4 col-sm-5 col-lg-4 list" style="margin-left: -15px;">
          <div class="leftimg">
            <div class="img">
              <img src="../img/about/mengban.png" alt="" style="width:100%;height:auto"/>
            </div>
            <!-- <div class="mask"></div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="team1">
        <div class="team">
          <div class="title">
            <div>
              <img src="../img/home/left1.png" />核心团队<img
                src="../img/home/right.png"
              />
            </div>
          </div>
          <div class="row item">
            <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6">
              <div
                class="photo row"
                v-for="(item, index) in 1"
                :key="index + 'q'"
              >
                <!-- <div class="col-xs-12 col-sm-12"> -->
                <div class="left">
                  <img src="../img/about/photo.png" alt="" />
                  <div class="name">涂晓帆</div>
                  <div class="major">首席科学家</div>
                  <div class="degree">武汉大学计算机专业博士系统分析师</div>
                </div>
                <!-- </div> -->
                <!-- <div  class="col-xs-12 col-sm-12"> -->
                <div class="right">
                  <div class="text">
                    我们的理想是“让每个孩子更高效、更公平地获取知识”。通过百度智慧课堂生态平台，我们得到了题库、视频、文库、问答、百度大脑等各类应用接口的支持，在这样的生态环境下，我们一定能够实现理想！
                  </div>
                </div>
                <!-- </div> -->
              </div>
            </div>

            <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6">
              <div
                class="photo row"
                v-for="(item, index) in 1"
                :key="index + 'r'"
              >
                <div class="left">
                  <img src="../img/about/photo1.png" alt="" />
                  <div class="name">樊慧津</div>
                  <div class="major">首席科学顾问</div>
                  <div class="degree">新加坡NTU控制科学博士中科院硕士</div>
                </div>
                <div class="right">
                  <div class="text">
                    智慧课堂生态是Al技术在教育领域的最佳实践，很荣幸VR技术能深度参与到这一生态建设中，通过VR教室解决方案的入驻为智慧课堂生态的繁荣助力！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="power">
        <div class="wrap">
          <div class="title">
            <div>
              <img src="../img/home/left1.png" />荣誉资质<img
                src="../img/home/right.png"
              />
            </div>
            <div>部分证书资质展示</div>
          </div>
          <div class="row picList">
            <div
              class="col-md-3 col-sm-3 col-xs-4 col-lg-2"
              v-for="(item, index) in bookImg"
              :key="index + '0'"
            >
              <div class="">
                <img :src="item" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="wrap">
          <div class="amap-page-container">
            <el-amap
              vid="amapDemo"
              :center="center"
              :zoom="zoom"
              class="amap-demo"
              :events="events"
              pitch-enable="false"
            >
              <el-amap-marker
                v-for="(marker, index) in markers"
                :key="index + '8'"
                :events="marker.events"
                :position="marker.position"
              />
              <el-amap-info-window
                v-if="windows.length != 0"
                :position="windows[0].position"
                :visible="true"
                :content="windows[0].content"
                :offset="windows[0].offset"
                :close-when-click-map="true"
                :is-custom="true"
              >
                <div id="info-window">
                  <p>{{ windows[0].address }}</p>
                  <div class="detail" @click="openMap">地址详情</div>
                </div>
              </el-amap-info-window>
            </el-amap>
          </div>
          <div class="form1">
            <div class="form">
              <div class="slogan">欢迎与我们合作，期待您的联系</div>
              <div>请填写您的信息，我们会在24小时内联系您</div>
              <el-form
                ref="form"
                :model="form"
                label-width="0px"
                :rules="rules"
              >
                <el-form-item prop="name" style="margin-bottom: 1rem">
                  <el-input
                    v-model="form.name"
                    placeholder="请输入您的姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="email">
                  <el-input
                    v-model="form.email"
                    style="margin-top: 0px"
                    placeholder="请输入您的邮箱地址"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  prop="type"
                  style="margin-top: 3rem; margin-bottom: 1.5rem"
                >
                  <el-input
                    v-model="form.type"
                    placeholder="请输入您的项目类型"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="problem">
                  <el-input
                    v-model="form.problem"
                    placeholder="请输入您的问题"
                    style="margin-bottom: 0px; margin-top: -1.5rem!imporatant"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="send">
                <div>立即发送</div>
                <!-- <img src="../img/home/rightArrows.png" alt=""> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  data() {
    const self = this;
    return {
      bookImg: [
        require("../img/about/bookimg/15.png"),
        require("../img/about/bookimg/16.png"),
        require("../img/about/bookimg/17.png"),
        require("../img/about/bookimg/1.png"),
        require("../img/about/bookimg/2.png"),
        require("../img/about/bookimg/3.png"),
        require("../img/about/bookimg/4.png"),
        require("../img/about/bookimg/5.png"),
        require("../img/about/bookimg/6.png"),
        require("../img/about/bookimg/7.png"),
        require("../img/about/bookimg/8.png"),
        require("../img/about/bookimg/9.png"),
        require("../img/about/bookimg/10.png"),
        require("../img/about/bookimg/11.png"),
        require("../img/about/bookimg/12.png"),
        require("../img/about/bookimg/13.png"),
        require("../img/about/bookimg/14.png"),

      ],
      rules: {
        name: [{ required: true, message: "请输入您的姓名", trigger: "blur" }],
        email: [
          { required: true, message: "请输入您的邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur"] },
        ],
        type: [
          { required: true, message: "请输入您的项目类型", trigger: "blur" },
        ],
        problem: [
          { required: true, message: "请输入您的问题", trigger: "blur" },
        ],
      },
      form: { name: "", email: "", type: "", problem: "" },
      data: [
        {
          position: "112.129023, 32.034523",
          address: "湖北鲲鹏芯科技有限公司",
        },
      ],
      zoom: 15,
      center: [112.129023, 32.034523],
      expandZoomRange: true,
      markers: [],
      windows: [],
      window: "",
      events: {
        click(e) {
          const { lng, lat } = e.lnglat;
        },
      },
      lng: 121.564254,
      lat: 31.21135,
    };
  },
  created() {},
  mounted() {
    this.point();
  },
  methods: {
    point() {
      const markers = [];
      const windows = [];
      const that = this;
      this.data.forEach((item, index) => {
        markers.push({
          position: item.position.split(","),
          // icon:item.url, //不设置默认蓝色水滴
          events: {
            mouseover() {
              // 方法：鼠标移动到点标记上，显示相应窗体
              that.windows.forEach((window) => {
                window.visible = false; // 关闭窗体
              });
              that.window = that.windows[index];
              that.$nextTick(() => {
                that.window.visible = true;
              });
            },
          },
        });
        windows.push({
          position: item.position.split(","),
          isCustom: true,
          offset: [-115, 55], // 窗体偏移
          showShadow: false,
          visible: false, // 初始是否显示
          address: item.address,
        });
      });
      //  加点
      this.markers = markers;
      // 加弹窗
      this.windows = windows;
    },
    checkDetail() {
      alert("点击了查看详情");
    },
    openMap() {
      window.open(
        "https://uri.amap.com/marker?position=112.129024,32.034532&name=创意园21号",
        "_blank"
      );
    },
  },
};
</script>

<style lang ="scss" scoped>
.row {
  margin-right: 0px;
  margin-left: 0px;
}
.bottom {
  margin-top: 1.5rem;
  background-color: #f5f7fa;
  width: 100%;
  /* height: 550px; */
  .wrap {
    display: flex;
    /* width: 1200px; */
    margin: 0 auto;
    position: relative;
    .amap-page-container {
      width: 100%;
    }
  }
}
.power {
  width: 100%;
  /* height: 650px; */
  .title {
    margin-top: 3rem;
    /* height: 1.2rem; */
    font-size: 1.2rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ff9200;
    line-height: 1.55rem;
    div:nth-child(1) {
      img:nth-child(1) {
        width: 3rem;
        margin-right: 1.5rem;
        min-width: 30px;
      }
      img:nth-child(2) {
        width: 3rem;
        margin-left: 1.5rem;
        min-width: 30px;
      }
    }
    div:nth-child(2) {
      margin-top: 20px;
      /* height: 0.8rem; */
      font-size: 0.8rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      /* line-height: 32px; */
    }
  }
  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    .picList {
      margin-top: 2.1rem;
      display: flex;
      flex-wrap: wrap;
      div {
        margin-bottom: 1.5rem;
        /* margin-right: 50px; */
        img {
          width: 100%;
          height: 100%;
        }
      }
      div:nth-child(5n) {
        /* margin-right: 0; */
      }
    }
  }
}
.top {
  width: 100%;
  background: linear-gradient(309deg, #003993 0%, #2a94d2 100%);
  .pic {
    position: relative;
    max-width: 1200px;
    width: 100%;
    height: 26rem;
    margin: 0 auto;
    .left {
      position: absolute;
      top: 9rem;
      left: 0.2rem;
      img {
        width: 24rem;
        height: 100%;
      }
    }
    .right {
      right: 20px;
      bottom: 0;
      /* top:2.2rem; */
      /* margin: 0 auto; */
      position: absolute;
      img {
        width: 18rem;
        height: 16rem;
      }
    }
  }
}
.main {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  .list_xs {
    padding: 15px;
    p {
      text-align: left;
      text-indent: 24px !important;
      font-size:12px ;
      line-height: 24px;
    }
    .leftimg{
      margin-right: 16px !important;
    }
  }
  .list {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;
    padding-bottom: 20px;
    
  }
  .list1 {
    margin-top: 20px;
  }
  .leftimg {
    // margin-right: 3rem;
    // width: 15rem;
    // height: 15rem;
    position: relative;
    border-radius: 5px;
    img {
      border-radius: 5px;
      width: 15rem;
      height: 15rem;
    }
    .mask {
      border-radius: 5px;
      margin-left: 1rem;
      width: 15rem;
      height: 15rem;
      background: #aecaf5;
      margin-top: -14.5rem;
      /* position: absolute; */
    }
  }
  .wrap {
    display: flex;
    padding: 2rem 0px 1rem;
    font-size: 0.7rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #4e4e4e;
    line-height: 32px;
    flex-wrap: wrap;
    p {
      text-indent: 1.5rem;
      margin-bottom: 0.5rem;
      span {
        /* color: #dd952c; */
        font-weight: 600;
      }
    }
  }
}
.amap-demo {
  height: 450px;
}
/* .amap-page-container {
  position: relative;
} */
#info-window {
  width: 8rem;
  height: 4.5rem;
  min-width: 120px;
  min-height: 60px;
  margin-left: 10px;
  margin-top: -110px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  p {
    line-height: 100%;
    text-align: ceter;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 120px; */
    margin: 0 auto;
    /* margin-top: 20px; */
    font-size: 1rem;
    padding: 1rem;
  }
  .detail {
    width: 100%;
    height: 24px;
    color: #fff;
    background-color: #1a73e8;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
}

/deep/ .el-input__inner {
  min-height: 40px;
  height: 2.5rem !important;

  background: rgba(55, 59, 72, 0.9);
  color: #ffffff;
}

.container {
  width: 100%;
  margin: 0 auto;
  margin-top: 0px;

  .form1 {
    position: absolute;
    margin-left: 60px;
    text-align: left;
    right: calc((100vw - 1220px) / 2);
    /* width: 1200px; */
    margin: 0 auto;
    /* left: 0; */
    /* right: 0; */
    display: flex;
    justify-content: flex-end;
    .form {
      min-width: 260px;
      min-height: 370px;
      max-width: 540px;
      width: 100%;
      height: 27rem;
      /* height: 580px; */
      background: rgba(55, 59, 72, 0.9);
      opacity: 0.94;
      padding: 0 0.6rem;
      box-sizing: border-box;
      z-index: 1000;
    }
    .slogan {
      margin-top: 1.5rem;
      /* width: 336px; */
      height: 1.56rem;
      font-size: 1.1rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 1.56rem;
    }
    div:nth-child(2) {
      /* width: 322px; */
      height: 17px;
      font-size: 0.8;
      font-family: PingFang SC;
      font-weight: 400;

      color: #babcc0;
      line-height: 1.55rem;
      margin-top: 1rem;
    }
    .el-form {
      margin-top: 2rem;
    }
    /deep/ .el-input__inner {
      border: 1px solid #999999;
    }
    .el-form-item {
      /* margin-top:40px; */
      /deep/.el-input {
        /* margin-bottom: 30px; */
        max-width: 360px;
        width: 100%;
      }
    }
    .send {
      margin-top: 0.5rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-right: 20px;
      width: 100%;
      div {
        min-width: 200px;
        width: 18rem;
        min-height: 40px;
        height: 2.2rem;
        background: #ff9200;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;

        color: #ffffff;
      }
    }
    .send:hover {
      cursor: pointer;
      transform: scale(1.02, 1.07);
      /* color: oldlace; */
      img {
        transform: scale(1.1, 1.1);
      }
    }
  }
  .team1 {
    width: 100%;
    background: #f5f7fa;
  }
  .team {
    margin-top: 1.5rem;
    width: 100%;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    /* height: 523px; */

    display: flex;
    align-items: center;
    /* justify-content: space-around; */
    flex-direction: column;
    text-align: left;
    padding-bottom: 20px;
    .title {
      margin-top: 3rem;
      /* width: 174px; */
      height: 23px;
      font-size: 1.2rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ff9200;
      line-height: 32px;
      div:nth-child(1) {
        img:nth-child(1) {
          width: 3rem;
          margin-right: 1.5rem;
          min-width: 30px;
        }
        img:nth-child(2) {
          width: 3rem;
          margin-left: 1.5rem;
          min-width: 30px;
        }
      }
    }
    .photo:nth-child(1) {
      /* margin-right: 10px; */
    }
    .item {
      display: flex;
      margin-top: 2.5rem;
      text-align: left;
      flex-wrap: wrap;
      .photo {
        margin-top: 60px;
        padding-bottom: 20px;
        width: 100%;
        min-height: 286px;
        /* height: 288px; */
        background: #ffffff;
        display: flex;
        opacity: 1;
        flex: 2;
        position: relative;
        /* div:nth-child(1){
          display: flex;
        } */
        img {
          position: absolute;
          width: 97px;
          height: 97px;
          border-radius: 50%;
          top: -46px;
          /* margin-left: -8px; */
        }
        .left {
          margin-left: 1.5rem;
          margin-top: 80px;
          .major {
            /* height: 19px; */
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #111111;
            line-height: 32px;
            /* opacity: 0.8; */
            margin-top: 20px;
          }
          .name {
            /* width: 56px; */
            height: 17px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ff9200;
            line-height: 32px;
          }
          .degree {
            max-width: 161px;
            margin-top: 0.4rem;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
          }
        }
        .right {
          flex: 4;
          margin-left: 1.55rem;
          margin-top: 86px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 36px;
          /* opacity: 0.8; */
          border-left: 1px solid rgba(255, 255, 255, 0.1);
          /* opacity: 0.1; */
          .text {
            margin-left: 1.5rem;
            padding-right: 10px;
          }
        }
      }
    }
    .photo:hover {
      img {
        transform: scale(1.2, 1.2);
      }
    }
  }
}
.amap-demo {
  height: 27rem;
  width: 100%;
  min-height: 370px;
}
@media screen and (max-width: 1200px) {
  .container .form1 {
    position: static;
  }
}
@media screen and (max-width: 770px) {
  .wrap {
    .col-xs-12:nth-child(1) {
      width: 30rem !important;
    }
  }
  .main .wrap {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 550px) {
  .container .team .title {
    font-size: 16px;
  }
  .power .title {
    font-size: 16px;
  }
  .container .form1 .el-form {
    .el-form-item {
      margin-bottom: 18px !important;
    }
    .el-form-item:nth-child(2) {
      margin-bottom: 40px !important;
    }
  }
  .container .form1 {
    position: static;
  }
  .container .team .item .photo {
    display: block;
  }
  .container .team .item .photo .right .text {
    margin-top: 10px;
    margin-left: 0;
    line-height: 24px;
    font-size: 12px;
  }
  .container .team .item .photo .right {
    margin-top: 0;
    margin-left: 1.5rem;
  }
}
@media screen and (max-width: 480px) {
  .top .pic .left {
    top: 12rem;
    left: 4.2rem;
    img {
      width: 26rem;
    }
  }
  .amap-demo {
    height: 27rem;
    width: 100%;
    min-height: 170px;
  }
  .container {
    padding-bottom: 40px;
  }
  .picList {
    .col-xs-4:nth-child(2n) {
      padding-right: 5px;
      padding-left: 5px;
    }
    .col-xs-4:nth-child(1n) {
      padding-right: 5px;
      padding-left: 5px;
    }
    .col-xs-4:nth-child(3n) {
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .container .form1 .slogan {
    margin-top: 24px;
    font-size: 16px;
  }
  .container .form1 div:nth-child(2) {
    font-size: 12px;
    margin-top: 15px;
  }
  .container .form1 .form {
    width: 100%;
    padding: 0 20px;
  }
  /* .container {
    margin-bottom: 550px;
  } */
  .main .wrap {
    padding: 0;
    line-height: 28px;
  }
  .bottom .wrap {
    display: block;
  }
  .container .form1 .send div {
    width: calc(100% - 40px);
  }
  .main .leftimg {
    margin-right: 30px;
  }
  .container .team .item .photo .left {
    margin-top: 60px;
  }
  .container .form1 .send {
    padding-right: 0;
    div {
      width: 100%;
    }
  }
}
</style>